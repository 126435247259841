import React, { useState, useEffect } from "react";
import TicketResponse from "./TicketResponse";
import AllNotes from "./AllNotes";
import TicketChat from "./TicketChat";
import { get_api, RenderHtml, RenderHtml1 } from "../../utils/LocalFunctions";
import axios from "axios";
import {
  ADD_NOTE,
  EDIT_ESCALATED_TICKETS,
  EDIT_PENDING_TICKETS,
  EDIT_RAISED_TICKET,
  permissions,
} from "../../utils/permissions";
import { render_category_remarks } from "../../pages/my-tickets/AgentAssignedTickets";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";
import Toaster from "../toaster/Toaster";

function SingleTicket(props) {
  const {
    ticketChat,
    issue,
    allTicketNotes,
    notes,
    setNotes,
    postTicketNotes,
    chatSessionId,
    user_id,
    ticekt_id,
    setRefresh,
    singleSelectedQueueDetails,
    fetchTickets,
    setRefreshTicketChat,
    refreshTicketChat,
    setError,
    error,
    setOpenEndchatModal,
    openEndchatModal,
    escalatedTicketFlag,
  } = props;

  const api = get_api();
  const cms_id = localStorage.getItem("cms_id");
  const [showtoast, setShowToast] = useState(false);

  const [ticketChatNote, setTicketChatNote] = useState([]);
  const [reopenTicketModal, setReopenTicketModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isApi, setIsApi] = useState(false);
  async function getTicketChat(ticketId) {
    if (!ticketId) return;
    try {
      const response = await axios.get(`${api}/tickets/${ticketId}/`);
      setRefreshTicketChat(false);
      setTicketChatNote(response?.data);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (showtoast) {
      const time = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => {
        clearTimeout(time);
      };
    }
  }, [showtoast]);

  useEffect(() => {
    if (issue?.id || refreshTicketChat) {
      getTicketChat(issue?.id);
    }
  }, [issue?.id, refreshTicketChat]);

  async function handleReopenTicket() {
    try {
      const response = await axios.put(`${api}/tickets/${issue?.id}/`, {
        status: "REOPEN",
        user_message: message,
        assigned_agent_id: parseInt(cms_id),
      });
      setReopenTicketModal(false);
      setShowToast(true);
      setRefreshTicketChat(false);
      fetchTickets();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="accordion-body-text-content flex-column width-100">
      <div className="flex-space-between">
        {(issue?.status === "RESOLVED" || issue.status === "CLOSED") && (
          <p
            className="reopen-ticket-btn"
            onClick={() => setReopenTicketModal(true)}
          >
            Reopen
          </p>
        )}

        {render_category_remarks([issue?.ticket_category])}
      </div>
      <div className="text-left">{RenderHtml(issue?.content)}</div>
      <div className="all-notes-ticket-chat-wrapper">
        <TicketChat
          // ticketChat={ticketChat}
          issue={issue}
          refreshTicketChat={refreshTicketChat}
          setRefreshTicketChat={setRefreshTicketChat}
          ticketChatNote={ticketChatNote}
        />

        <AllNotes
          issue={issue}
          // allTicketNotes={allTicketNotes}
          notes={notes}
          setNotes={setNotes}
          postTicketNotes={postTicketNotes}
          ticketChatNote={ticketChatNote}
          getTicketChat={getTicketChat}
          setRefreshTicketChat={setRefreshTicketChat}
        />
      </div>
      {(issue.status === "PENDING" ||
        issue.status === "RAISED_TICKET" ||
        issue.status === "USER_ACTION_REQUIRED" ||
        issue.status === "ESCALATED" ||
        issue.status === "USER_RESPONDED") &&
        (issue?.status === "USER_ACTION_REQUIRED"
          ? permissions.includes(EDIT_RAISED_TICKET)
          : true) &&
        (issue?.status === "ESCALATED"
          ? permissions.includes(EDIT_ESCALATED_TICKETS)
          : true) &&
        (issue?.status === "PENDING"
          ? permissions.includes(EDIT_PENDING_TICKETS)
          : true) && (
          <TicketResponse
            chatSessionId={singleSelectedQueueDetails?.chat_session_id}
            user_id={singleSelectedQueueDetails?.user_id}
            ticekt_id={issue.id}
            setRefresh={setRefresh}
            issue={issue}
            fetchTickets={fetchTickets}
            setRefreshTicketChat={setRefreshTicketChat}
            error={error}
            setError={setError}
            setOpenEndchatModal={setOpenEndchatModal}
            openEndchatModal={openEndchatModal}
            tickets={true}
            escalatedTicketFlag={escalatedTicketFlag}
            handleReopenTicket={handleReopenTicket}
          />
        )}

      {reopenTicketModal && (
        <ConfirmationModal
          show={reopenTicketModal}
          onHide={() => setReopenTicketModal(false)}
          saveClickHandler={() => handleReopenTicket()}
          api={isApi}
          title={"Are you sure to reopen this ticket?"}
          isTextField={true}
          inputValue={message}
          setInputValue={setMessage}
        />
      )}

      {showtoast && <Toaster messages={["Success"]} show={showtoast} />}
    </div>
  );
}

export default SingleTicket;
