import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

// Create a context for WebSocket
const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const subscribersRef = useRef({});
  const reconnectAttemptsRef = useRef(0); // Keep track of reconnect attempts
  const maxReconnectAttempts = 30; // Limit the number of reconnect attempts

  useEffect(() => {
    let ws;

    const connectSocket = (flag) => {
      // Initialize WebSocket connection
      const protocol = window.location.protocol === "https:" ? "wss" : "ws";
      let socket_url = `wss://ancillarygcp-prod.smytten.com/ws/`;
      ws = new WebSocket(socket_url);

      ws.addEventListener("open", () => {
        console.log("WebSocket connection established");
        reconnectAttemptsRef.current = 0; // Reset reconnect attempts on successful connection
        if (flag) {
          reSubscribeToAll();
          // window.location.reload();
        }
      });

      ws.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        const { group, message } = data;
        if (subscribersRef.current[group]) {
          subscribersRef.current[group](message);
        }
        if (subscribersRef.current[group + "notification"]) {
          subscribersRef.current[group + "notification"](message);
        }
        console.log(subscribersRef.current);
      });

      ws.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
        console.log("Close Code:", event.code);
        console.log("Close Reason:", event.reason);
        attemptReconnect(); // Try to reconnect when the connection is closed
      };

      ws.addEventListener("error", (error) => {
        console.error("WebSocket error:", error);
        ws.close(); // Close the socket on error to trigger reconnection
      });

      setSocket(ws);
    };

    const attemptReconnect = () => {
      if (reconnectAttemptsRef.current < maxReconnectAttempts) {
        const reconnectDelay = Math.min(
          1000 * Math.pow(2, reconnectAttemptsRef.current),
          30000
        ); // Exponential backoff (up to 30 seconds)

        reconnectAttemptsRef.current += 1;
        console.log(
          `Attempting to reconnect... (#${reconnectAttemptsRef.current})`
        );

        setTimeout(() => {
          connectSocket(true);
        }, reconnectDelay);
      } else {
        console.log("Max reconnect attempts reached.");
      }
    };

    const reSubscribeToAll = () => {
      console.log("Re-subscribing to all channels...");
      Object.keys(subscribersRef.current).forEach((groupName) => {
        const callback = subscribersRef.current[groupName];
        console.log(`Re-subscribing to ${groupName}`);
        subscribeToGroup(groupName, callback); // Re-subscribe to each group
      });
    };

    connectSocket(); // Initial connection

    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    let pingInterval;
    if (socket) {
      const startPing = () => {
        pingInterval = setInterval(() => {
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ action: "ping" }));
            console.log("Ping sent to keep the connection alive.");
          }
        }, 30000); // Send ping every 30 seconds
      };

      socket.addEventListener("pong", () => {
        console.log("Pong received from server.");
      });

      startPing();
    }

    return () => {
      clearInterval(pingInterval);
    };
  }, [socket]);

  const subscribeToGroup = (groupName, onMessageReceived, additonal_key) => {
    if (socket) {
      const subscribeMessage = JSON.stringify({
        action: "subscribe",
        group: groupName,
      });

      if (socket.readyState === WebSocket.OPEN) {
        console.log("subscribing to", groupName);
        socket.send(subscribeMessage);
        // WebSocket is ready, send the message
      } else {
        // If not OPEN, wait for it to open before sending
        console.log("trying to subscribe", groupName);
        socket.onopen = () => {
          console.log("WebSocket connection opened, subscribing to", groupName);
          socket.send(subscribeMessage);
        };
      }

      if (additonal_key) {
        subscribersRef.current = {
          ...subscribersRef.current,
          [groupName + additonal_key]: onMessageReceived,
        };
      } else {
        subscribersRef.current = {
          ...subscribersRef.current,
          [groupName]: onMessageReceived,
        };
      }
    }
  };

  const unsubscribeFromGroup = (groupName) => {
    console.log("unsubscribing to", groupName);
    if (socket) {
      const unsubscribeMessage = JSON.stringify({
        action: "unsubscribe",
        group: groupName,
      });
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(unsubscribeMessage); // WebSocket is ready, send the message
      }

      // Remove the group from the subscribersRef
      const { [groupName]: _, ...remainingSubscribers } =
        subscribersRef.current;
      subscribersRef.current = remainingSubscribers;
    }
  };

  const sendMessagetoGrop = (groupName, messageContent) => {
    if (socket) {
      const sendMessage = JSON.stringify({
        action: "send",
        group: groupName,
        message: messageContent,
      });
      socket.send(sendMessage);
    }
  };

  const showSuccessToast = (message, position) => {
    toast(
      <div
        style={{ color: "white", fontSize: "12px" }}
        className="flex-flex-start-center"
      >
        {message ? message : "New Message Received"}
      </div>,
      {
        position: position ? position : "top-right", // You can choose the position
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "custom-toast-container",
      }
    );
  };

  // const playNotificationSound = () => {
  //   const notificationSound =
  //     "https://assets.mixkit.co/active_storage/sfx/2867/2867-preview.mp3";
  //   const audio = new Audio(notificationSound);
  //   audio?.play();
  // };

  return (
    <WebSocketContext.Provider
      value={{
        subscribeToGroup,
        sendMessagetoGrop,
        unsubscribeFromGroup,
        showSuccessToast,
        socket,
        // playNotificationSound,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use WebSocket context
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
