import React from "react";
import { Modal } from "react-bootstrap";
import { crossBlack } from "../../utils/SvgIcons";

export default function TicketCloseModal(props) {
  const {
    first_btn,
    second_btn,
    first_btn_class,
    first_btn_text,
    second_btn_class,
    second_btn_text,
    content,
    onHide,
    error,
  } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div style={{ padding: "12px 24px 24px 24px" }}>
        <p
          className="flex-flex-end-center cursor-p"
          style={{
            paddingBottom: "12px",
          }}
          onClick={onHide}
        >
          {crossBlack}
        </p>
        <div style={{ gap: "16px" }} className="flex width-100">
          <div
            onClick={() => {
              first_btn();
            }}
            className={first_btn_class}
            style={{
              flex: 1,
            }}
          >
            {first_btn_text}
          </div>
          <div
            onClick={() => {
              second_btn();
            }}
            style={{
              flex: 1,
            }}
            className={second_btn_class}
          >
            {second_btn_text}
          </div>
        </div>
        {error && <p className="error-message mt-5px">{error}</p>}
      </div>
    </Modal>
  );
}
