import React from "react";
import Modal from "react-bootstrap/Modal";
import MenuItem from "@mui/material/MenuItem";
import { crossBlack } from "../../utils/SvgIcons";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export function renderStatusName(value) {
  switch (value) {
    case "RESOLVED":
      return "Resolved";
    case "CLOSED":
      return "Closed";
    case "USER_ACTION_REQUIRED":
      return "User Action Required";
    case "RAISED_TICKET":
      return "Ticket Raised";
    case "ESCALATED":
      return "Escalated";
    case "PENDING":
      return "Pending";
    case "USER_RESPONDED":
      return "User Responded";

    default:
      return value;
  }
}

function TicketRaiseModal(props) {
  const {
    show,
    onHide,
    setSelectedStatus,
    selectedStatus,
    create_ticket,
    options,
    escalated,
    handleChangeFunc,
    onClickSubmitHandler,
    notes,
    setNotes,
    setOpenEndchatModal,
 
  } = props;
  const status = [ "CLOSED", "USER_ACTION_REQUIRED"];

  const displayOptions = options?.length > 0 ? options : status;

  function handleChange(e) {
    const value = e.target.value;
    if (escalated) {
      handleChangeFunc(e.target.value);
    } else {
      setSelectedStatus(e.target.value);
      if (value === "CLOSED" || value === "RESOLVED") {
        onHide();
        setOpenEndchatModal(true);
      }
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="ticket-raise-modal-main-container flex-column">
        <p className="flex-flex-end-center" onClick={onHide}>
          {crossBlack}
        </p>
        <div>
          <FormControl fullWidth size="small">
            <InputLabel id="dropdown-label">Select Status</InputLabel>
            <Select
              labelId="dropdown-label"
              value={selectedStatus}
              label="Select Status"
              onChange={handleChange}
            >
              {displayOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {renderStatusName(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {escalated && (
          <div>
            <input
              type="text"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter your notes here..."
              className="conversation-notes-input"
            />
          </div>
        )}
        <div
          className="flex-flex-end-center mt-10px ticket-raise-modal-btn"
          onClick={() => {
            if (escalated) {
              onClickSubmitHandler();
            } else {
              create_ticket("STATUS");
            }
          }}
        >
          <button>Submit</button>
        </div>
      </div>
    </Modal>
  );
}

export default TicketRaiseModal;
