import React, { useState } from "react";
import "./Pagination.css";

import arrowPage2 from "../../images/page-arrow-2.svg";
import arrowPage1 from "../../images/page-arrow-1.svg";

const Pagination = ({ currentPage, totalPages, onPageChange,   applyFilter }) => {
  const [inputPage, setInputPage] = useState("");

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
      setInputPage("");
    }
  };

  const handleInputChange = (event, newPage) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (newPage >= 1 && newPage <= totalPages) {
        onPageChange(newPage);
        setInputPage("");
      }
      if(newPage>totalPages){
        onPageChange(totalPages);
        setInputPage("");
      }
      event.target.blur();
    }
  };

  const renderPagination = () => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pages.push(
          <span
            key={i}
            className={
              i === currentPage
                ? "active-page-number-single"
                : "non-active-page-number-single"
            }
            onClick={() => handlePageChange(i)}
            style={{ userSelect: "none" }}
          >
            {i}
          </span>
        );
      } else if (
        (i === currentPage - 2 && i > 1) ||
        (i === currentPage + 2 && i < totalPages)
      ) {
        pages.push(<span key={i}>...</span>);
      }
    }

    return pages;
  };

  return (
    <div className="pagination-container-main-wrapper-new">
      <div
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img src={arrowPage2} alt='arrow-page' />
      </div>

      {renderPagination()}

      <div
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <img src={arrowPage1} alt='arrwo-page-1'/>
      </div>

      <div className="pagination-input-container">
        Go to
        <input
          type="text"
          value={inputPage}
          onChange={(e) => {
            if(e.target.value <= totalPages){
              setInputPage(e.target.value)
            }
            
          }}
          onKeyDown={(e) => handleInputChange(e, parseInt(inputPage, 10))}
        />
      </div>
    </div>
  );
};

export default Pagination;
