export const VIEW_DASHBOARD = "VIEW_DASHBOARD";
export const VIEW_ALL_CHATS = "VIEW_ALL_CHATS";
export const SELF_ASSIGN_CHAT = "SELF_ASSIGN_CHAT";
export const VIEW_MY_CHATS = "VIEW_MY_CHATS";
export const CAN_GO_ONLINE = "CAN_GO_ONLINE";
export const DELEGATE_TICKET = "DELEGATE_TICKET";
export const VIEW_AGENT_SETTINGS = "VIEW_AGENT_SETTINGS";
export const EDIT_AGENT_SETTINGS = "EDIT_AGENT_SETTINGS";
export const EDIT_AGENT_STATUS = "EDIT_AGENT_STATUS";
export const EDIT_BOT_SETTINGS = "EDIT_BOT_SETTINGS";
export const DELEGATE_CHAT = "DELEGATE_CHAT";
export const SELF_ASSIGN_TICKET = "SELF_ASSIGN_TICKET";
export const VIEW_MY_TICKETS = "VIEW_MY_TICKETS";
export const VIEW_DAILY_CHATS = "VIEW_DAILY_CHATS";
export const VIEW_BOT_SETTINGS = "VIEW_BOT_SETTINGS";
export const RESOLVE_TICKET = "RESOLVE_TICKET";
export const BOT_CREATION = "BOT_CREATION";

export const ADD_NOTE="ADD_NOTE";
export const RAISE_TICKET="RAISE_TICKET";
export const ESCALATE_TICKET="ESCALATE_TICKET"
export const VIEW_RAISED_TICKET="VIEW_RAISED_TICKET";
export const EDIT_RAISED_TICKET="EDIT_RAISED_TICKET";
export const EDIT_ESCALATED_TICKETS="EDIT_ESCALATED_TICKETS";
export const VIEW_ESCALATED_TICKETS="VIEW_ESCALATED_TICKETS";
export const VIEW_PENDING_TICKETS="VIEW_PENDING_TICKETS";
export const EDIT_PENDING_TICKETS="EDIT_PENDING_TICKETS";
export const VIEW_TICKET_SETTINGS="VIEW_TICKET_SETTINGS";
export const EDIT_TICKET_SETTINGS="EDIT_TICKET_SETTINGS";
export const VIEW_QUEUE_TICKTES="VIEW_QUEUE_TICKTES";
export const EDIT_QUEUE_TICKETS="EDIT_QUEUE_TICKETS";
export const MAKE_CALL="MAKE_CALL";

let permissions;

try {
  const storedData = localStorage.getItem("permissions");
  permissions = storedData ? JSON.parse(storedData) : [];
} catch (error) {
  console.error("Error parsing JSON from localStorage:", error);
  permissions = []; // Default to an empty array if JSON is invalid
}

export { permissions };
































