import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import "./CustomSingleSelectDropdown.css";
import { capsLetter } from "../../utils/utils";

const CustomSingleSelectDropdown = forwardRef(
  ({ label, options, value, setvalue, handleClick }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(value || "");

    // Expose methods to parent via ref
    useImperativeHandle(ref, () => ({
      focus: () => {
        setIsOpen(true); // Open the dropdown when focused
        document.querySelector(`#${label}`).focus(); // Set focus to the input
      },
    }));

    const filteredOptions =
      options.filter((option) =>
        option?.toLowerCase().includes(value?.toLowerCase().trim())
      ) || options;

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        const trimmedSearchTerm = value.trim();
        if (trimmedSearchTerm) {
          const convertLowerCase = options?.map((option) =>
            option.toLowerCase()
          );
          if ([...convertLowerCase].includes(trimmedSearchTerm.toLowerCase())) {
            handleClick(capsLetter(trimmedSearchTerm));
            setIsOpen(false);
            // setSearchTerm(searchTerm);
          }
        }
      }
    };

    return (
      <div className="dropdown-container">
        <label className="dropdown-label">{label}</label>
        <div
          className="dropdown-input"
          onClick={() => setIsOpen(!isOpen)}
          tabIndex={0}
        >
          <input
            id={label} // Unique id to focus this input
            type="text"
            autocomplete="off"
            className="width-100"
            value={value}
            onChange={(e) => setvalue(e.target.value)}
            placeholder={`Search ${label}`}
            onKeyDown={handleKeyDown} // Handle key down event
          />
        </div>

        {isOpen && (
          <div className="dropdown-options">
            <ul className="dropdown-list">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <li
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      handleClick(option);
                      setIsOpen(false);
                      setvalue(option);
                    }}
                  >
                    { option}
                  </li>
                ))
              ) : (
                <li className="dropdown-item">No options found</li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
);

export default CustomSingleSelectDropdown;
