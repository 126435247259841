import React, { useEffect, useRef, useState } from "react";
import EndChatModal from "../modals/end-chat-modal/EndChatModal";
import TemplatesModal from "../modals/templates-modal/TemplatesModal";
import ChatModal from "../modals/chat-modal/ChatModal";
import {
  ai_enhancement,
  cms_api,
  modules,
  topWidgetsList,
} from "../chat-bot/AiAssistanceBot";
import {
  permissions,
  RAISE_TICKET,
  RESOLVE_TICKET,
} from "../../utils/permissions";
import axios from "axios";
import {
  cleanHtml,
  convertHtmlToText,
  convertTextToHtml,
  get_api,
  parsevalue,
} from "../../utils/LocalFunctions";
import { message_type } from "../chatInterface/ChatInterface";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Popover } from "@mui/material";
import SelectionModal from "../modals/selection_modal/SelectionModal";
import { headers } from "../../App";
import TicketCloseModal from "./TicketCloseModal";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import TicketRaiseModal from "./TicketRaiseModal";
import Toaster from "../toaster/Toaster";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";

export default function TicketResponse({
  chatSessionId,
  user_id,
  ticekt_id,
  setRefresh,
  issue,
  fetchTickets,
  setRefreshTicketChat,
  setError,
  error,

  tickets,
  escalatedTicketFlag,
  
}) {
  const [chat_text, setChatText] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const chatTextArea = useRef(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [chatmodals, setChatModals] = useState({
    modal: false,
    value: "",
    text: "",
  });
  const [refund_list, setRefundList] = useState([]);
  const [return_request, setReturnRequest] = useState([]);
  const [user_order_list, setUserOrderList] = useState([]);
  const [recomendedTempltes, setRecomendedtemplates] = useState({
    name: "",
    list: [],
  });
  const [selection_modal, setSelectionModal] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [ticket_confimation, setTicketConfirmation] = useState(false);
  const [ticketRaiseModal, setTicketRaiseModal] = useState(false);
  const api = get_api();
  const cms_id = localStorage.getItem("cms_session_id");
  const cms_username = localStorage.getItem("cms_user_name");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const navigate = useNavigate();
  const [showtoast, setShowToast] = useState(false);
  const [isApi, setIsApi] = useState(false);
  const [openEndchatModal, setOpenEndchatModal] = useState(false);

  useEffect(() => {
    if (showtoast) {
      const time = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => {
        clearTimeout(time);
      };
    }
  }, [showtoast]);

  useEffect(() => {
    axios
      .get(`${api}/template/`)
      .then((res) => {
        setAllTemplates(res?.data?.content);
      })
      .catch((e) => {
        console.log(e?.message);
      });
    axios
      .post(
        `${cms_api}/discover_order/cms/order_return/refund_list`,
        {
          page: {
            page_id: 0,
            limit: 50,
          },
          search: user_id,
          filter_by: {
            status: "cancel",
            order_type: "both",
            from_date: "",
            to_date: "",
          },
        },
        { headers }
      )
      .then((res) => {
        setRefundList(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${cms_api}/discover_order/cms/order/user_order_list`,
        { user_id: user_id },
        {
          headers,
        }
      )
      .then((res) => {
        setUserOrderList(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${cms_api}/discover_order/cms/order_return/return_request`,
        {
          page: { page_id: 0, limit: 50 },
          search: user_id,
          filter_by: {
            status: "ALL",
            order_type: "both",
            from_date: "",
            to_date: "",
          },
        },
        {
          headers,
        }
      )
      .then((res) => {
        setReturnRequest(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function defaultChatText(element) {
    if (element.value === "CLOSE_CHAT") {
      return "End chat";
    } else if (element.value === "UPLOAD_IMAGE") {
      return "Image upload";
    } else if (element.value === "FEEDBACK_REQUEST") {
      return "Request feedback";
    } else if (element.value === "CLOSE_TICKET") {
      return "Close ticket";
    } else {
      return "";
    }
  }
  function handleClickWidjet(element) {
    setChatModals((prev) => ({
      modal: true,
      value: element.value,
      text: defaultChatText(element),
    }));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  

  const convert_text = (e) => {
    if (!loader) {
      setLoader(true);
      handleClose();
      axios
        .post(`${api}/ai_inhancement/`, {
          text: chat_text,
          action: e.value,
        })
        .then((res) => {
          setChatText(res.data?.enhanced_text);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  function sendMessage(message) {
    const userMessage = message?.return_value;

    if (userMessage !== "") {
      if (message) {
        // if (message?.dont_show) {
        // } else {
        //   setChatHistory([...chat_history, { ...message, sender_type: "BOT" }]);
        // }
      }
      const allParams = {
        bot_id: 21,
        session_id: "4093c697-1587-4df6-b434-4335483f01f4",
        user_id: user_id,
        user_name: "Swathy",
        current_chat_id: chatSessionId,
        sender_type: "USER",
        proxy_name: localStorage.getItem("proxy_name"),
        is_agent: true,
      };
      let paramsObj = { ...allParams };
      paramsObj.user_message = userMessage;
      paramsObj.selected_options = message?.options ? message?.options : [];
      paramsObj.type = message?.type ? message?.type : message_type?.message;
      paramsObj.source = "BOT";

      if (message.type === "IMAGE") {
        paramsObj.user_message = message.user_message;
        paramsObj.image_url = message.image_url;
      }

      axios
        .put(`${api}/chatsession/${paramsObj?.current_chat_id}/`, paramsObj)
        .then((data) => {
          setChatModals({
            modal: false,
            value: "",
            text: "",
          });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function last_characters(text) {
    if (text.length > 1) {
      return text[text.length - 2] + text[text.length - 1] === "/t";
    } else {
      return false;
    }
  }

  function searchByCategory(searchString) {
    // Filter items whose categories include the search string as part of any category
    const filteredList = allTemplates.filter((item) =>
      item?.categories?.some((category) =>
        category.toLowerCase().includes(searchString.toLowerCase())
      )
    );

    // Return the object with the search string as the name and the filtered list
    return {
      name: searchString,
      list: filteredList,
    };
  }

  const handleKeyDown = (e) => {
    e.stopPropagation();
    const value = convertHtmlToText(e?.target?.innerHTML);
    if (last_characters(value)) {
      setChatModals(() => ({
        modal: true,
        value: "templates",
        text: "",
      }));
    }
    if (value.length > 2 && value.split(" ").length === 1) {
      let obj = searchByCategory(value);
      setRecomendedtemplates({ ...obj });
    }
    if (value.length < 3 || value.split(" ").length > 1) {
      setRecomendedtemplates({
        name: "",
        list: [],
      });
    }
  };
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  function renderPayloadKey() {
    if (issue?.status === "USER_ACTION_REQUIRED") {
      return "cms_status";
    } else {
      return "status";
    }
  }

  function sendPayload(type) {
    if (type === "RAISE") {
      if (issue?.status === "PENDING") {
        return {
          status: "USER_ACTION_REQUIRED",
          reason: chat_text,
          user_message: chat_text,
          user_session_id: cms_id,
          agent_name: cms_username,
          user_id: parseInt(searchParams.get("user_id")),
        };
      } else {
        return {
          cms_status: "USER_ACTION_REQUIRED",
          reason: chat_text,
          user_message: chat_text,
          user_session_id: cms_id,
          agent_name: cms_username,
          user_id: parseInt(searchParams.get("user_id")),
        };
      }
    } else {
      const user_message = stripHtmlTags(chat_text);
      return {
        cms_status: selectedStatus,
        user_message: user_message.trim(),
        agent_name: cms_username,
        cms_ticket_id: issue?.cms_ticket_id,
      };
    }
  }

  const create_ticket = (type) => {
    setIsLoading(true);

    axios
      .put(`${api}/tickets/${ticekt_id}/`, sendPayload(type))
      .then((res) => {
        setChatText("");
        setRefreshTicketChat(true);
        setTicketRaiseModal(false);
        setSelectedStatus("");
        setShowToast(true);

        if (escalatedTicketFlag) {
          navigate("/tickets");
        }
        if (
          type === "RAISE" ||
          issue?.status === "RAISED_TICKET" ||
          issue?.status === "USER_RESPONDED" ||
          issue?.status === "USER_ACTION_REQUIRED" ||
          issue?.status === "ESCALATED"
        ) {
          fetchTickets();
        }
        setTicketConfirmation(false);
      })
      .catch((err) => {
        if (type === "RAISE") {
          setError(err?.response?.data?.error);
        }
        setShowToast(false);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  async function handleCloseTicket() {
    setIsApi(true);
    try {
      const response = await axios.put(`${api}/tickets/${ticekt_id}/`, {
        status: "RESOLVED",
        closing_category: "Resolved via chat",
        closing_reason: "-",
        closing_sub_reason: "-",
        closing_notes: "",
      });
      setChatModals((prev) => ({
        modal: false,
        value: "",
        text: "",
      }));
      setIsApi(false);
      setShowToast(true);
      fetchTickets();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="message-container position-relative">
      <div
        style={{
          border: "1px solid #dadada",
          borderBottom: "none",
          padding: "10px",
        }}
        className="flex-space-between-center width-100"
      >
        <div className="top-widgets-text-chatbot ">
          {topWidgetsList
            ?.filter((e) => {
              if (e.permission === "ALL") {
                return true;
              } else if (permissions.includes(e.permission)) {
                return true;
              }
              return false;
            })
            ?.map((list, i) => {
              if (issue?.status === "PENDING") {
                return (
                  list.value !== "CLOSE_CHAT" &&
                  list.value !== "UPLOAD_IMAGE" && (
                    <p
                      key={i}
                      className="single-widget-chat cursor-p"
                      onClick={() => handleClickWidjet(list)}
                    >
                      {list?.name}
                    </p>
                  )
                );
              } else {
                return (
                  list.value !== "UPLOAD_IMAGE" &&
                  list.value !== "CLOSE_CHAT" && (
                    <p
                      key={i}
                      className="single-widget-chat cursor-p"
                      onClick={() => handleClickWidjet(list)}
                    >
                      {list?.name}
                    </p>
                  )
                );
              }
            })}

          {issue?.status === "PENDING" && (
            <p
              className="single-widget-chat cursor-p"
              onClick={() =>
                handleClickWidjet({
                  value: "CLOSE_TICKET",
                })
              }
            >
              {"Close ticket"}
            </p>
          )}
        </div>
        <div
          aria-describedby={id}
          onClick={handleClick}
          className="ai-assistance-box flex-center-center cursor-p"
        >
          Ai Assistance
          {loader && (
            <div
              class="spinner-border  ml-10px"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                color: "#8639fc",
              }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>

      <div
        className={`recomendation-template-container box-shadow ${
          recomendedTempltes?.list?.length === 0 ? "fade-out" : "fade-in"
        }`}
        style={{ zIndex: 10 }}
      >
        <div className="recomendation-name-container">Smart Suggestions</div>
        <div className="recomendation-template-list">
          {recomendedTempltes?.list?.map((e, i) => {
            return (
              <div
                onClick={() => {
                  setChatText(convertTextToHtml(e?.text));
                  if (e?.context?.length) {
                    setSelectionModal(true);
                    setSelectionList(parsevalue(e?.context));
                  }

                  chatTextArea.current.focus();
                  setRecomendedtemplates({
                    name: "",
                    list: [],
                  });
                }}
                className="recomendation-single-template cursor-p"
                key={i}
              >
                {/* {e?.text} */}
                {e?.text?.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            );
          })}
        </div>
      </div>

      <div
        onKeyDownCapture={(e) => {
          if (
            e.code === "Enter" &&
            !e.altKey &&
            !e.ctrlKey &&
            !e.metaKey &&
            !e.shiftKey
          ) {
            e.preventDefault();
            e.stopPropagation();
            // handleSend();
            if (issue?.status === "PENDING") {
              setTicketConfirmation(true);
            } else {
              setTicketRaiseModal(true);
              // create_ticket("RESOLVE");
            }
          }
        }}
      >
        <ReactQuill
          ref={chatTextArea}
          value={chat_text}
          onChange={(value) => {
            setChatText(value);
          }}
          modules={modules}
          theme="snow"
          className="react-quil-container-custom"
          style={{ height: "120px" }}
          onKeyUp={handleKeyDown}
        />
      </div>

      {chatmodals?.modal &&
        !["templates", "delegation", "CLOSE_CHAT", "CLOSE_TICKET"].includes(
          chatmodals?.value
        ) && (
          <ChatModal
            show={chatmodals.modal}
            onHide={() => {
              setChatModals((prev) => ({
                modal: false,
                value: "",
                text: "",
              }));
            }}
            title={chatmodals.value}
            setChatText={setChatText}
            apiCall={(message) => {
              sendMessage({
                return_value: message,
                message: message,
                type: chatmodals.value,
              });
            }}
            chatmodals={chatmodals}
          />
        )}

      {chatmodals?.modal && ["CLOSE_TICKET"].includes(chatmodals?.value) && (
        <ConfirmationModal
          show={chatmodals?.modal}
          onHide={() => {
            setChatModals((prev) => ({
              modal: false,
              value: "",
              text: "",
            }));
          }}
          saveClickHandler={() => {
            handleCloseTicket();
          }}
          api={isApi}
          title={"Are you sure to close the ticket?"}
        />
      )}

      {chatmodals?.modal &&
        ["templates", "delegation"].includes(chatmodals?.value) && (
          <TemplatesModal
            show={chatmodals?.modal}
            onHide={() => {
              setChatModals((prev) => ({
                modal: false,
                value: "",
                text: "",
              }));
            }}
            title={chatmodals?.value}
            allTemplates={allTemplates}
            apiCall={(message) => {
              // sendMessage(message);
              setChatText(message?.message);
            }}
            tickets={tickets}
            issue={issue}
          />
        )}

      {chatmodals?.modal && ["CLOSE_CHAT"].includes(chatmodals?.value) && (
        <EndChatModal
          show={chatmodals.modal}
          onHide={() => {
            setChatModals(() => ({
              modal: false,
              value: "",
              text: "",
            }));
            setChatText("");
            fetchTickets();
            setChatText("");
            setRefreshTicketChat(true);
          }}
          title={chatmodals.value}
          setChatText={setChatText}
          chatSessionId={chatSessionId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          TicketResponse={true}
          ticekt_id={ticekt_id}
          message={chat_text}
          setChatModals={setChatModals}
          fetchTickets={fetchTickets}
          setError={setError}
          issue={issue}
        />
      )}

      {openEndchatModal && (
        <EndChatModal
          show={openEndchatModal}
          onHide={() => {
            setOpenEndchatModal(false);
            setChatText("");
          }}
          title={chatmodals.value}
          setChatText={setChatText}
          chatSessionId={chatSessionId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          TicketResponse={true}
          ticekt_id={ticekt_id}
          message={chat_text}
          setChatModals={setChatModals}
          fetchTickets={fetchTickets}
          setError={setError}
          selectedStatus={selectedStatus}
          issue={issue}
          setRefreshTicketChat={setRefreshTicketChat}
        />
      )}
      {selection_modal && (
        <SelectionModal
          show={selection_modal}
          onHide={() => {
            setSelectionModal(false);
          }}
          selectionList={selectionList}
          refund_list={refund_list}
          return_request={return_request}
          user_order_list={user_order_list}
          text={chat_text}
          setChatText={setChatText}
        />
      )}

      {ticketRaiseModal && (
        <TicketRaiseModal
          show={ticketRaiseModal}
          onHide={() => {
            setSelectedStatus(null);
            setTicketRaiseModal(false);
          }}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
          create_ticket={create_ticket}
          setOpenEndchatModal={setOpenEndchatModal}
          escalatedTicketFlag={escalatedTicketFlag}
        />
      )}

   

      {ticket_confimation &&
        (permissions.includes(RAISE_TICKET) ||
          permissions.includes(RESOLVE_TICKET)) && (
          <TicketCloseModal
            show={ticket_confimation}
            onHide={() => {
              setTicketConfirmation(false);
            }}
            first_btn_text={"Raise Ticket in  CMS"}
            second_btn_text={"Mark Ticket Resolved"}
            first_btn={() => {
              if (!isLoading) {
                create_ticket("RAISE");
                setRefresh(true);
              }
            }}
            second_btn={() => {
              // create_ticket("RESOLVE");
              setTicketConfirmation(false);
              if (issue?.status === "PENDING") {
                setChatModals(() => ({
                  modal: true,
                  value: "CLOSE_CHAT",
                  text: "End Chat",
                }));
              }
              setRefresh(true);
            }}
            first_btn_class={"confirm-btn confirm-btn-green"}
            second_btn_class={"confirm-btn confirm-btn-red"}
            content={"Please select the next step"}
            error={error}
            setError={setError}
          />
        )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        className="pop-over-content-container"
      >
        <div className="pop-over-content">
          {ai_enhancement.map((e, i) => {
            return (
              <p
                onClick={() => {
                  convert_text(e);
                }}
                className="mt-5px"
                key={i}
              >
                {e.label}
              </p>
            );
          })}
        </div>
      </Popover>

      {showtoast && <Toaster messages={["Success"]} show={showtoast} />}
    </div>
  );
}
