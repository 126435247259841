import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./CreateTicket.css";
import { crossBlack } from "../../../utils/SvgIcons";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ticketCategoryList } from "../../../utils/utils";
import axios from "axios";
import { get_api } from "../../../utils/LocalFunctions";
import { cms_api } from "../../chat-bot/AiAssistanceBot";
import { useWebSocket } from "../../../utils/Socket";

export default function CreateTicket(props) {
  const { onHide } = props;
  const [query, setQuery] = useState("");
  const [all_users, setallAUsers] = useState([]);
  const [agentquery, setAgentQuery] = useState("");
  const [all_agents, setallAgents] = useState([]);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [agentRecomendation, setAgentRecomendation] = useState(false);
  const [issue, setIssue] = useState("");
  const [issue_summary, setIssueSummary] = useState("");
  const [selected_agent, setSelctedAgent] = useState({});
  const [selected_user, setSelectedUser] = useState({});
  const recommendationsRef = useRef(null);
  const agentRef = useRef(null);
  const api = get_api();

  const { showSuccessToast } = useWebSocket();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        recommendationsRef.current &&
        !recommendationsRef.current.contains(event.target)
      ) {
        setShowRecommendations(false);
      }
    };
    const handleClickOutside1 = (event) => {
      if (agentRef.current && !agentRef.current.contains(event.target)) {
        setAgentRecomendation(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const get_all_agents = () => {
    axios
      .get(`${api}/csa/`)
      .then((res) => {
        setallAgents(res?.data?.result?.map((e) => ({ ...e, changes: false })));
      })
      .catch((e) => {
        console.log(e?.message);
      });
  };
  useEffect(() => {
    get_all_agents();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      axios
        .post(
          `${cms_api}/discover_user/cms/users/user_list`,
          { search: query },
          {
            headers: {
              Authorization: localStorage.getItem("cms_session_id"),
              session_id: localStorage.getItem("cms_session_id"),
            },
          }
        )
        .then((res) => {
          setallAUsers(res.data?.content?.users ?? []);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);

    return () => clearTimeout(handler);
  }, [query]);

  const check_all_selected = () => {
    return (
      issue_summary !== "" &&
      issue !== "" &&
      Object.keys(selected_user).length > 0 &&
      Object.keys(selected_agent).length > 0
    );
  };

  const saveTicket = () => {
    if (check_all_selected()) {
      axios
        .post(`${api}/raise_ticket/`, {
          issue_summary: issue_summary,
          user_id: selected_user.id,
          issue_title: issue,
          category: issue,
          agent_id: selected_agent.id,
        })
        .then((res) => {
          setTimeout(() => {
            showSuccessToast("Ticket Created", "bottom-center");
          }, 1000);
          onHide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="lg-modal-admin"
    >
      <div className="create-ticket-container">
        <div
          style={{ borderBottom: "1px solid #ccc" }}
          className="flex-space-between-center"
        >
          <p className="font-weight-600">Create Ticket</p>
          <p
            onClick={() => {
              onHide();
            }}
          >
            {crossBlack}
          </p>
        </div>
        <div
          className="pb-10px mt-10px hide-scroll"
          style={{ height: "calc(100% - 74px)", overflowY: "scroll" }}
        >
          <div className="flex-flex-start-flex-start width-100">
            <p style={{ width: "200px" }} className="side-heading mt-10px">
              Select User
            </p>
            <div ref={recommendationsRef} className="right-content">
              {Object.keys(selected_user).length > 0 ? (
                <div
                  style={{ height: "40px" }}
                  className="flex-flex-start-center"
                >
                  <p>{selected_user?.name}</p>
                  <p
                    onClick={() => {
                      setSelectedUser({});
                    }}
                    className="ml-20px cursor-p"
                  >
                    {crossBlack}
                  </p>
                </div>
              ) : (
                <input
                  className="conversation-notes-input"
                  type="text"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    setShowRecommendations(true);
                  }}
                  placeholder="Search..."
                  style={{
                    width: "100%",
                  }}
                  onFocus={() => {
                    setShowRecommendations(true);
                  }}
                />
              )}

              {showRecommendations && (
                <div
                  style={{
                    minHeight: "80px",
                    maxHeight: "300px",
                  }}
                  className="recomendation-container hide-scroll"
                >
                  {all_users.map((user, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedUser({ ...user });
                          setShowRecommendations(false);
                        }}
                        key={index}
                        className="recomendation-strip flex-space-between-center"
                      >
                        <div
                          style={{ gap: "8px" }}
                          className="flex-flex-start-center"
                        >
                          <p className="recomendation-strip-value">
                            {user?.id}
                          </p>
                          <p className="recomendation-strip-value">
                            {user?.name}
                          </p>
                        </div>
                        <div className="brand-search-term-value-text">
                          {user?.email}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="flex-flex-start-flex-start width-100 mt-20px">
            <p style={{ width: "200px" }} className="side-heading mt-10px">
              Issue Type
            </p>
            <div className="right-content">
              <FormControl
                className="material-ui-select"
                sx={{ m: 0, width: "100%", height: "40px" }}
              >
                <InputLabel id="label">Issue</InputLabel>
                <Select
                  value={issue}
                  onChange={(e) => {
                    setIssue(e.target.value);
                  }}
                  input={<OutlinedInput label="Issue" />}
                >
                  {ticketCategoryList.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex-flex-start-flex-start width-100 mt-20px">
            <p style={{ width: "200px" }} className="side-heading mt-10px">
              Issue
            </p>
            <div className="right-content">
              <textarea
                placeholder="Write Summury here..."
                className="width-100 issue-summary"
                style={{ height: "100px" }}
                value={issue_summary}
                onChange={(e) => {
                  setIssueSummary(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex-flex-start-flex-start width-100 mt-20px">
            <p style={{ width: "200px" }} className="side-heading mt-10px">
              Agnet
            </p>
            <div ref={agentRef} className="right-content">
              {Object.keys(selected_agent).length > 0 ? (
                <div
                  style={{ height: "40px" }}
                  className="flex-flex-start-center"
                >
                  <p>{selected_agent?.name}</p>
                  <p
                    onClick={() => {
                      setSelctedAgent({});
                    }}
                    className="ml-20px cursor-p"
                  >
                    {crossBlack}
                  </p>
                </div>
              ) : (
                <input
                  className="conversation-notes-input"
                  type="text"
                  value={agentquery}
                  onChange={(e) => {
                    setAgentQuery(e.target.value);
                    setAgentRecomendation(true);
                  }}
                  placeholder="Search..."
                  style={{
                    width: "100%",
                  }}
                  onFocus={() => {
                    setAgentRecomendation(true);
                  }}
                />
              )}
              {agentRecomendation && (
                <div
                  style={{
                    maxHeight: "200px",
                  }}
                  className="recomendation-container"
                >
                  {all_agents
                    ?.filter((e) => {
                      return e?.name
                        ?.toLowerCase()
                        .includes(agentquery.toLowerCase());
                    })
                    .map((agent, index) => {
                      return (
                        <div
                          onClick={() => {
                            setSelctedAgent({ ...agent });
                            setAgentRecomendation(false);
                          }}
                          key={index}
                          className="recomendation-strip"
                        >
                          <p className="recomendation-strip-value">
                            {agent?.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ gap: "12px" }} className="width-100 flex-flex-end-center">
          <div
            onClick={() => {
              onHide();
            }}
            className="white-btn"
          >
            Cancel
          </div>
          <div
            onClick={() => {
              saveTicket();
            }}
            className="purple-btn"
          >
            Save
          </div>
        </div>
      </div>
    </Modal>
  );
}
