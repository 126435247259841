import React, { useState, useEffect, useRef, useContext } from "react";
import "./TemplatesModal.css";
import Modal from "react-bootstrap/Modal";
import { crossBlack } from "../../../utils/SvgIcons";
import axios from "axios";
import { get_api } from "../../../utils/LocalFunctions";
import { useNavigate, useParams } from "react-router";
import MaterialUITable from "../../material-ui-table/MaterialUiTable";
import { capsLetter } from "../../../utils/utils";
import Toaster from "../../toaster/Toaster";
import { RefreshContext } from "../../../context/RefreshTicketsContext";

function TemplatesModal(props) {
  const { show, onHide, allTemplates, apiCall, title, tickets, issue } = props;
  const [allMails, setAllMails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedMail, setSelectedMail] = useState({});
  const [open_confirmation, setOpenConfirmation] = useState(false);
  const searchInputRef = useRef(null);
  const { chat_session_id } = useParams();
  const [showtoast, setShowToast] = useState(false);
  const api = get_api();
  const { refreshTickets, setRefreshTickets } = useContext(RefreshContext);

  useEffect(() => {
    if (showtoast) {
      const time = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => {
        clearTimeout(time);
      };
    }
  }, [showtoast]);
  const navigate = useNavigate();

  const filteredTemplates = allTemplates.filter((template) =>
    template?.text?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredMails = allMails.filter((mail) => {
    return mail?.email?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const lightGradients = {
    1: "linear-gradient(135deg, #64b5f6 0%, #bbdefb 100%)", // Light Blue
    TICKET_AGENT: "linear-gradient(135deg, #f06292 0%, #f8bbd0 100%)", // Light Pink
    3: "linear-gradient(135deg, #9575cd 0%, #d1c4e9 100%)", // Light Purple
    TICKET_ADMIN: "linear-gradient(135deg, #ffb74d 0%, #ffe0b2 100%)", // Light Orange
    ESCALATE_TICKET_AGENT: "linear-gradient(135deg, #9575cd 0%, #d1c4e9 100%)", // Light Yellow
  };

  function checkTicketPermissions(e) {
    if (
      e === "TICKET_AGENT" ||
      e === "TICKET_ADMIN" ||
      e === "ESCALATE_TICKET_AGENT"
    ) {
      return true;
    }

    return false;
  }

  const render_remarks = (obj) => {
    return (
      <div style={{ gap: "8px" }} className="flex-flex-start-center">
        {obj?.map((e) => {
          return (
            checkTicketPermissions(e) && (
              <div
                style={{
                  background: lightGradients[e]
                    ? lightGradients[e]
                    : lightGradients[1],
                  color: "white",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                {e}
              </div>
            )
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    axios
      .get(`${api}/csa/`)
      .then((res) => {
        setAllMails(res?.data?.result);
      })
      .catch((e) => {
        console.log(e?.message);
      });
  }, []);

  useEffect(() => {
    if (show) {
      searchInputRef.current?.focus();

      if (filteredTemplates.length > 0 && !searchTerm) {
        setSelectedTemplate(filteredTemplates[0]?.id);
      }
    }
  }, [show, filteredTemplates, searchTerm]);

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template?.id);
    setSearchTerm(template?.text);
    if (template?.id) {
      apiCall({
        return_value: template?.text,
        message: template?.text,
        type: template?.type,
      });
      onHide();
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchTerm.trim() !== "") {
      const isSearchTermValid = filteredTemplates.find(
        (element) => element.text === searchTerm
      );

      if (isSearchTermValid) {
        apiCall({
          return_value: searchTerm,
          message: searchTerm,
          type: "MESSAGE",
        });
        onHide();
      }
    }
  };

  const send_Deligation = () => {
    if (tickets) {
      axios
        .post(`${api}/ticket_delegation/`, {
          ticket_id: issue?.id,
          delegated_agent_id: selectedMail?.id,
        })
        .then((res) => {
          setShowToast(true);
          onHide();
          setOpenConfirmation(false);
          localStorage.setItem("ticket_sidebar", 1);
          navigate("/tickets");
          setRefreshTickets(true);
          window.location.reload();
        })
        .catch((err) => {
          setShowToast(false);
          console.log(err);
        });
    } else {
      axios
        .put(`${api}/chatsession/${chat_session_id}/`, {
          agent_email: selectedMail.email,
        })
        .then((res) => {
          navigate("/chats");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="template-modal-conatiner height-100"
    >
      <div className="templates-modal height-100 ">
        <div className="templates-modal-top-container flex-space-between">
          <p className="font-weight-600">
            {title?.charAt(0).toUpperCase() + title?.slice(1)}
          </p>
          <span onClick={onHide} className="cursor-p">
            {crossBlack}
          </span>
        </div>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          onKeyDown={handleKeyDown}
          className="templates-search-bar"
          ref={searchInputRef}
        />
        {title === "delegation" ? (
          <div
            style={{ maxHeight: "calc(100% - 110px)" }}
            className="templates-list hide-scroll  width-100"
          >
            {tickets ? (
              <MaterialUITable
                columns={[
                  {
                    key: "id",
                    label: "Id",
                  },
                  {
                    key: "name",
                    label: "Name",
                  },
                  {
                    key: "email",
                    label: "Email",
                  },
                  {
                    key: "roles",
                    label: "Roles",
                    formatter: (value) => {

                      return <div>{render_remarks(value)}</div>;
                    },
                  },
                ]}
                data={filteredMails?.sort((a, b) => {
                  // Sort by status first (ONLINE before OFFLINE)
                  const rolesDiff = b.roles.length - a.roles.length;
                  if (rolesDiff !== 0) return rolesDiff;
                })}
                rowClickHandler={(row) => {
                  setSelectedMail(row);
                  setOpenConfirmation(true);
                }}
                rowsShowPerPage={10}
                selectedRow={selectedMail}
              />
            ) : (
              <MaterialUITable
                columns={[
                  {
                    key: "status",
                    label: "Status",
                    formatter: (value) => {
                      return (
                        <div
                          className="flex-flex-start-center"
                          style={{
                            gap: "5px",
                          }}
                        >
                          <div
                            className="dot-status-templates "
                            style={{
                              backgroundColor:
                                value === "ONLINE" ? "green" : "red",
                            }}
                          ></div>
                          <p className="">{capsLetter(value)}</p>
                        </div>
                      );
                    },
                  },
                  {
                    key: "id",
                    label: "Id",
                  },
                  {
                    key: "name",
                    label: "Name",
                  },
                  {
                    key: "email",
                    label: "Email",
                  },
                  {
                    key: "free_slots",
                    label: "Free Slots",
                  },
                ]}
                data={filteredMails?.sort((a, b) => {
                  // Sort by status first (ONLINE before OFFLINE)
                  if (a.status === "ONLINE" && b.status === "OFFLINE")
                    return -1;
                  if (a.status === "OFFLINE" && b.status === "ONLINE") return 1;
                  // If status is the same, sort by name alphabetically
                  return a.name.localeCompare(b.name);
                })}
                rowClickHandler={(row) => {
                  if (row?.status === "ONLINE") {
                    setSelectedMail(row);
                    setOpenConfirmation(true);
                  }
                }}
                rowsShowPerPage={10}
                selectedRow={selectedMail}
              />
            )}
          </div>
        ) : (
          <div className="templates-list width-100">
            {filteredTemplates?.length > 0 ? (
              filteredTemplates?.map((template) => (
                <div
                  key={template.id}
                  className={`template-item ${
                    selectedTemplate === template?.id ? "selected" : ""
                  }`}
                  onClick={() => handleTemplateClick(template)}
                  style={{
                    backgroundColor:
                      selectedTemplate === template?.id && "#8369fc",
                  }}
                >
                  {template?.text}
                </div>
              ))
            ) : (
              <p className="no-results">No templates found</p>
            )}
          </div>
        )}
      </div>

      {open_confirmation && title === "delegation" && (
        <Modal
          show={open_confirmation}
          onHide={() => {
            setOpenConfirmation(false);
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <div style={{ padding: "24px" }}>
            <p>Are you sure </p>
            <div
              style={{ gap: "16px" }}
              className="flex-flex-end-center width-100"
            >
              <div
                onClick={() => {
                  setOpenConfirmation(false);
                }}
                className="white-btn"
              >
                Cancel
              </div>
              <div
                onClick={() => {
                  send_Deligation();
                }}
                className="purple-btn"
              >
                Send
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showtoast && (
        <Toaster messages={["Delegated successfully"]} show={showtoast} />
      )}
    </Modal>
  );
}

export default TemplatesModal;
