import React, { useEffect, useRef, useState } from "react";
import "./Chatinterface.css";
import { useSearchParams } from "react-router-dom";
import {
  RenderHtml,
  get_api,
  postData,
  redirectToPath,
} from "../../utils/LocalFunctions";

import ORDER_LIST from "./types/ORDER_LIST";
import OPTION_LIST from "./types/OPTION_LIST";
import TRIAL_LIST from "./types/TRIAL_LIST";
import SHOP_LIST from "./types/SHOP_LIST";
import UPLOAD_IMAGE from "./types/UPLOAD_IMAGE";
import FEEDBACK_REQUEST from "./types/FEEDBACK_REQUEST";
import { formatTimeStamp } from "../../utils";

const ai_message_classname = "ai-message";
const user_message_classname = "user-message";

export const MULTIPLE_SELECTION = "MULTIPLE_SELECTION";
export const SINGLE_SELECTION = "SINGLE_SELECTION";

export const message_type = {
  message: "MESSAGE",
  options: "OPTIONS",
  order_list: "ORDER_LIST",
  trial_list: "TRIAL_LIST",
  shop_list: "SHOP_LIST",
  upload_image: "UPLOAD_IMAGE",
  image: "IMAGE",
  breakpoint: "BREAKPOINT",
  feedback_request: "FEEDBACK_REQUEST",
  quit: "QUIT",
};

export default function ChatInterface({
  allParams,
  r_chat_history,
  parentchatContainerRef,
  allChats,
  bot_type,
  toggleOptions,
  show_scroll,
  usersendMessage,
  mytickets,
}) {
  const [chat_history, setChatHistory] = useState([...r_chat_history]);

  const [selected_options, setSelectedOptions] = useState([]);

  const chatContainerRef = useRef(null);

  const [conversation_type, setConversationType] = useState("");

  function scrollChatToBottom() {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }

  function scrollChatToTop() {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = 0;
    }
  }

  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      scrollChatToBottom();
      isFirstLoad.current = false;
    }

    if (allChats) {
      scrollChatToTop();
    }
  }, []);

  useEffect(() => {
    setChatHistory([...r_chat_history]);
  }, [r_chat_history]);

  function sendMessage(message) {
    if (usersendMessage) {
      usersendMessage(message.return_value);
    }
  }

  const optionClickHandler = (option) => {
    if (option.selection_type === MULTIPLE_SELECTION) {
      const index_of_element = selected_options.findIndex((o) => {
        if (option?.message_type === message_type.options) {
          return o?.text === option?.text;
        } else {
          return o?.name === option?.name;
        }
      });

      if (index_of_element > -1) {
        setSelectedOptions([
          ...selected_options.slice(0, index_of_element),
          ...selected_options.slice(index_of_element + 1),
        ]);
      } else {
        setSelectedOptions([...selected_options, option]);
      }
    } else {
      if (option?.deeplink) {
        redirectToPath(option?.deeplink);
      } else {
        sendMessage({
          message: option.text,
          type: option?.message_type,
          return_value: option?.return_value,
          options: [option],
        });
      }
    }
  };
  const ctaClickHandler = (message) => {
    let return_value = "";
    for (let o of selected_options) {
      return_value += o?.return_value + "|";
    }
    sendMessage({
      return_value: return_value.slice(0, -1),
      message: "Selected values",
      options: selected_options,
      type: message?.type,
    });
    setSelectedOptions([]);
  };

  const render_messages = (message, click_enable, latest) => {
    switch (message.type) {
      case message_type.message:
        return (
          <div>
            <p className="message-text">{RenderHtml(message?.message)}</p>
            <p className="chat-time-stamp font-8px">
              {formatTimeStamp(message?.timestamp)}
            </p>
          </div>
        );
      case message_type.options:
        return (
          // bot_type && toggleOptions &&
          <OPTION_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
            bot_type={bot_type}
            toggleOptions={toggleOptions}
          />
        );
      case message_type.order_list:
        return (
          <ORDER_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
            bot_type={bot_type}
            toggleOptions={toggleOptions}
          />
        );
      case message_type?.trial_list:
        return (
          <TRIAL_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
            bot_type={bot_type}
            toggleOptions={toggleOptions}
          />
        );

      case message_type?.shop_list:
        return (
          <SHOP_LIST
            message={message}
            click_enable={click_enable}
            optionClickHandler={optionClickHandler}
            selected_options={selected_options}
            latest={latest}
            bot_type={bot_type}
            toggleOptions={toggleOptions}
          />
        );

      case message_type?.breakpoint:
        return (
          <div>
            <p className="message-text">{message?.message}</p>
            <p className="chat-time-stamp">
              {formatTimeStamp(message?.timestamp)}
            </p>
          </div>
        );
      case message_type?.upload_image:
        return (
          <UPLOAD_IMAGE
            message={message}
            click_enable={click_enable}
            sendMessage={sendMessage}
          />
        );
      case message_type?.feedback_request:
        return <FEEDBACK_REQUEST sendMessage={sendMessage} />;

      //   case message_type?.quit:
      //     return <QUIT />;
      case message_type?.image:
        return <UPLOAD_IMAGE type={"sender"} message={message} />;

      default:
        return (
          <div>
            <p className="message-text">{RenderHtml(message?.message)}</p>
            <p className="chat-time-stamp">
              {formatTimeStamp(message?.timestamp)}
            </p>
          </div>
        );
    }
  };

  return (
    <div className="chat-container">
      <div
        className={`chat-history ${show_scroll ? "" : "hide-scroll"} `}
        ref={parentchatContainerRef}
      >
        {/* {allChats && <p className="font-16px font-weight-600">Chat histroy</p>} */}
        {chat_history?.map((message, index) => {
          const className =
            message.sender_type ===
            (allParams["sender_type"] ? allParams["sender_type"] : "BOT")
              ? ai_message_classname
              : user_message_classname;

          const click_enable =
            message?.sender_type === "BOT" && index === chat_history.length - 1
              ? true
              : false;
          const latest = index === chat_history.length - 1;
          return (
            <>
              {message?.type === message_type.breakpoint && (
                <div
                  style={{ margin: "28px 0px " }}
                  className="position-relative"
                >
                  <div className="partision"></div>
                  <div className="break-point-partision flex-flex-start-center mb-16px">
                    <div className="dot"></div>
                    <p className="mr-16px ml-16px breakpoint-text">
                      {message?.message}
                    </p>
                    <div className="dot"></div>
                  </div>
                </div>
              )}
              <div className={`${className}-parent`}>
                <div
                  className={`chat-message ${className} ${message?.type}`}
                  key={index}
                  style={{
                    backgroundColor:
                      mytickets &&
                      message?.sender_type === "BOT" &&
                      message?.message.includes(
                        "Great news! Your request has been successfully logged."
                      ) &&
                      "#ff9874",
                  }}
                >
                  {render_messages(message, click_enable, latest)}
                  {message?.selection_type === MULTIPLE_SELECTION ? (
                    <div
                      onClick={() => {
                        if (click_enable) {
                          ctaClickHandler(message);
                        }
                      }}
                      className="option-container-cta mt-12px"
                    >
                      <p className="option-text">
                        {message?.submit_cta ? message?.submit_cta : "send"}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          );
        })}
      </div>

      {conversation_type === "SESSION_CLOSED" && (
        <div className="input-container flex-column">
          <p className="restart-conversation-text">
            This has been marked as closed. If you have any other queries
            regarding this issues
          </p>
          <div
            onClick={() => {
              sendMessage({
                message: "hi",
                type: message_type.message,
                return_value: "hi",
                options: [],
              });
            }}
            className="restart-conversation mt-16px width-100"
          >
            Restart Conversation
          </div>
        </div>
      )}
    </div>
  );
}
