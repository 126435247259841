import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, Suspense, lazy, startTransition, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { get_api } from "./utils/LocalFunctions";
import ProtectedRoute from "./routes/ProtectedRoute";
import "./App.css";
import "./global.css";

import { ToastContainer } from "react-toastify";
import { useWebSocket } from "./utils/Socket";
import { cms_api } from "./components/chat-bot/AiAssistanceBot";

const Login = lazy(() => import("./pages/login/Login"));
const Header1 = lazy(() => import("./components/header/Header1"));
const MyChats = lazy(() => import("./pages/my-chats/MyChats"));
const ChatAdmin = lazy(() => import("./pages/chat-admin/ChatAdmin"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const Header = lazy(() => import("./components/header/Header"));
const MyTickets = lazy(() => import("./pages/my-tickets/MyTickets"));
const AllChats = lazy(() => import("./pages/all-chats/AllChats"));
const LazySettings = lazy(() => import("./pages/settings/Settings"));
const LazyDailyChats = lazy(() => import("./pages/daily-chats/DailyChats"));
const LazyBotList = lazy(() =>
  import("../src/components/chat_bot_setup/bots/Botlist/Botlist")
);

const LazyBotDetails = lazy(() =>
  import("../src/components/chat_bot_setup/bots/Botlist/BotDetails")
);

const LazyCreateAgent = lazy(() =>
  import("../src/components/chat_bot_setup/bots/Agents/CreateAgent")
);

const LazyCreateTool = lazy(() =>
  import("../src/components/chat_bot_setup/bots/Tools/CreateTool")
);

const LazyFlowDetails = lazy(() =>
  import("../src/components/chat_bot_setup/flow/Flow/FlowDeatils")
);
const LazyCreateHelperFunction = lazy(() =>
  import(
    "../src/components/chat_bot_setup/flow/HelperFunction/CreateHelperFunction"
  )
);

const LazyAllAgents = lazy(() =>
  import("../src/components/chat_bot_setup/bots/Agents/AllAgents")
);

export const headers = {
  Authorization: localStorage.getItem("cms_session_id"),
  session_id: localStorage.getItem("cms_session_id"),
};

function App() {
  const [searchParams] = useSearchParams();
  const api = get_api();
  const navigate = useNavigate();
  const [cms_token_expire, setCmsTokenExpire] = useState(false);
  const { subscribeToGroup, unsubscribeFromGroup, showSuccessToast } =
    useWebSocket();

  function get_user_id(mail) {
    return new Promise((resp, rej) => {
      axios
        .get(`${api}/csa/?email=${mail}`)
        .then((res) => {
          localStorage.setItem("cms_id", res.data.id);
          localStorage.setItem("proxy_name", res.data.proxy_name);
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.permissions)
          );
          localStorage.setItem("roles", res.data.roles);
          resp(res);

          subscribeToGroup(`${res.data.id}`, (message) => {
            showSuccessToast();
          });

          startTransition(() => {
            navigate("/chatadmin");
          });
        })
        .catch((r) => {
          if (r?.status !== 200) {
            startTransition(() => {
              navigate("/not-found", { replace: true });
            });
          }
        });
    });
  }

  useEffect(() => {
    if (searchParams.get("cms_session_id")) {
      localStorage.clear();
      localStorage.setItem("cms_user_name", searchParams.get("cms_agent_name"));
      localStorage.setItem(
        "cms_session_id",
        searchParams.get("cms_session_id")
      );
      localStorage.setItem("cms_email", searchParams.get("cms_user_email"));
      get_user_id(searchParams.get("cms_user_email"));
    }
    return () => {
      unsubscribeFromGroup(
        `GLOBAL_ACTIVE_CHATS_${localStorage.getItem("cms_id")}`
      );
    };
  }, []);

  useEffect(() => {
    let l1 = setInterval(() => {
      axios
        .post(
          `${cms_api}/discover_user/cms/users/user_list`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("cms_session_id"),
              session_id: localStorage.getItem("cms_session_id"),
            },
          }
        )
        .then((res) => {
          setCmsTokenExpire(false);
        })
        .catch((err) => {
          setCmsTokenExpire(true);
        });
    }, 15000);

    return () => {
      clearInterval(l1);
    };
  }, []);

  return (
    <div className="App">
      {cms_token_expire && (
        <div className="cms-token-expired" style={{}}>
          CMS token is expired , Relaunch Page Again{" "}
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/not-found"
            element={
              <div className="flex-center-center">
                <img
                  src={
                    "https://forum.hestiacp.com/uploads/default/9364dfee3bd1ffb5997bcd34ede3e9883bf12e8b"
                  }
                  alt="not-found-icon"
                />
              </div>
            }
          />
          <Route
            path="/chats/:chat_session_id?"
            element={
              <ProtectedRoute element={<Header1 component={<MyChats />} />} />
            }
          />
          <Route
            path="/chatadmin"
            element={
              <ProtectedRoute element={<Header component={<ChatAdmin />} />} />
            }
          />

          <Route
            path="/tickets"
            element={
              <ProtectedRoute element={<Header1 component={<MyTickets />} />} />
            }
          />
          <Route
            path="/allchats"
            element={
              <ProtectedRoute element={<Header1 component={<AllChats />} />} />
            }
          />
          <Route
            path="/dailychats"
            element={
              <ProtectedRoute
                element={<Header1 component={<LazyDailyChats />} />}
              />
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute
                element={<Header component={<LazySettings />} />}
              />
            }
          />
          <Route
            path="/bots"
            element={
              <ProtectedRoute
                element={<Header component={<LazyBotList />} />}
              />
            }
          />
          <Route
            path="/bots/details/:botid"
            element={
              <Suspense>
                <Header component={<LazyBotDetails></LazyBotDetails>} />
              </Suspense>
            }
          />

          <Route
            path="/bots/:botid/agent/:id?"
            element={
              <Suspense>
                <Header component={<LazyCreateAgent></LazyCreateAgent>} />
              </Suspense>
            }
          />

          <Route
            path="/bots/:botid/tool/:id?"
            element={
              <Suspense>
                <Header component={<LazyCreateTool></LazyCreateTool>} />
              </Suspense>
            }
          />

          <Route
            path="/bots/:botid/flow/:flowid/"
            element={
              <Suspense>
                <Header component={<LazyFlowDetails />} />
              </Suspense>
            }
          />
          <Route
            path="/bots/:botid/helperfunction/:h_id?"
            element={
              <Suspense>
                <Header component={<LazyCreateHelperFunction />} />
              </Suspense>
            }
          />
          <Route
            path="/bots/:botid/flow/:flowid/helperfunction/:h_id?"
            element={
              <Suspense>
                <Header component={<LazyCreateHelperFunction />} />
              </Suspense>
            }
          />
          <Route
            path="/bots/:botid/allagents"
            element={
              <Suspense>
                <Header component={<LazyAllAgents />} />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>

      <ToastContainer />
    </div>
  );
}

export default App;
