import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import "./TicketDetails.css";
import "../chat-bot/ChatBot.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import ChatInterface from "../chatInterface/ChatInterface";
import axios from "axios";
import {
  get_api,
  GetSearchParams,
  redirectToPath,
} from "../../utils/LocalFunctions";
import {
  ESCALATE_TICKET,
  MAKE_CALL,
  permissions,
  VIEW_ESCALATED_TICKETS,
  VIEW_PENDING_TICKETS,
  VIEW_QUEUE_TICKTES,
  VIEW_RAISED_TICKET,
} from "../../utils/permissions";
import {
  faAngleLeft,
  faAngleRight,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";
import Pagination from "../pagination/Pagination";

import SingleTicket from "./SingleTicket";
import TicketRaiseModal, { renderStatusName } from "./TicketRaiseModal";
import { RefreshContext } from "../../context/RefreshTicketsContext";
import { formatTimeStamp } from "../../utils";
import moment from "moment";
import { formatDateRange } from "../../pages/all-chats/AllChats";
import CallUserModal from "../modals/call-user-modal/CallUserModal";
import { InfoICON } from "../../utils/SvgIcons";
import { Tooltip } from "antd";

export function callDetailsToolTipContent(data) {
  return (
    <div className="tool-tip-text-ticket flex-center-center flex-column">
      <p className="call-user-tool-tip font-12px font-weight-600">
        Last Called Placed Date :
      </p>
      <p className="call-user-tool-tip font-12px">
        {data?.callUserData?.created_at
          ? formatTimeStamp(data?.callUserData?.created_at)
          : ""}
      </p>
      <p className="call-user-tool-tip font-12px font-weight-600">
        Last Called Agent :
      </p>
      <p className="call-user-tool-tip font-12px">
        {data?.callUserData?.agent}
      </p>
      <p className="call-user-tool-tip font-12px font-weight-600">
        Calling Reason :
      </p>
      <p
        className="call-user-tool-tip font-12px"
        style={{
          textAlign: "center",
        }}
      >
        {data?.callUserData?.remark}
      </p>
    </div>
  );
}

export default function TicketDetails({
  backClickHandler,
  my_tickets,
  escalatedTickets,
}) {
  const { refreshTickets, setRefreshTickets } = useContext(RefreshContext);
  const navigate = useNavigate();
  const [singleSelectedQueueDetails, setSingleSelectedQueueDetails] = useState(
    {}
  );
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const parentchatContainerRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isApi, setIsApi] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [notes, setNotes] = useState("");
  const [ticketChat, setTicketChat] = useState([]);
  const [allTicketNotes, setAllTicketNotes] = useState([]);
  const [error, setError] = useState("");
  const [escalatedModal, setEscalatedModal] = useState(false);

  const [refreshTicketChat, setRefreshTicketChat] = useState(false);
  const api = get_api();
  const cms_id = localStorage.getItem("cms_id");
  const [isLoading, setIsLoading] = useState(false);
  const [openEndchatModal, setOpenEndchatModal] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [escalatedType, setEscalatedType] = useState(null);
  const [selectedIssue, setSelectedIssue] = useState({});
  const [selectedEscalatedValue, setSelectedEscalatedValue] = useState(
    Object.keys(selectedIssue)?.length > 0
      ? selectedIssue?.escalated_type
      : null
  );

  const cms_username = localStorage.getItem("cms_user_name");
  const ticketSidebar = parseInt(localStorage.getItem("ticket_sidebar"));
  const escalatedOptions = [
    "Shown as delivered but not delivered - OTP verified",
    "Shown as delivered but not delivered - OTP not verified",
    "Delayed Delivery",
    "On Hold Destination",
    "Updated Phone Number",
    "RTO Request",
    "Misrouted",
    "Delivery Agent's Rude Behaviour",
    "False update",
    "Unable to raise",
    "SG/ Out TAT/ Unsatisfied",
    "DTO of multiple SKUs/DTO csncelled",
    "Replacement Order",
    "Refund rejected by Finance Team",
    "Tech Glitch/CMS Issue",
    "Reopen & Resolve (only Ecom)",
    "Vin Sync Issue",
    "Refund-RTO",
    "Refund-APC",
    "Refund-BPC",
    "Refund-Drop",
    "Refund-2% BPC",
    "Refund-Cancelled in System",
    "Refund-SG",
    "Refund-CF",
    "Refund-SAD",
    "Refund-DMD Manual",
    "Refund-Lost",
    "Refund-Damaged",
    "Coupon Code",
    "SB Credit-RTO",
    "SB Credit-APC",
    "SB Credit-BPC",
    "SB Credit-Buck Glitch",
    "SB Credit-SG",
    "SB Credit-DTO",
    "SB Credit-SAD",
    "SB Credit-DMD Manual",
    "SB Credit-Lost",
    "SB Credit-Damaged",
  ];

  const initializeDateRange = () => {
    const dateRangeParam = searchParams.get("date_range");
    if (dateRangeParam && dateRangeParam !== "") {
      const { start_date, end_date } = formatDateRange(dateRangeParam, "");
      return {
        startDate: start_date || todaysDate,
        endDate: end_date || todaysDate,
      };
    }
    return {
      startDate: "",
      endDate: "",
    };
  };
  const maxDate = moment().format("YYYY-MM-DD");
  const todaysDate = maxDate;
  const [all_ticket_ids, SetAllTicketsIds] = useState([]);
  const [curr_index, setCurrentIndex] = useState(-1);
  const statusFilter = searchParams.get("status_filter") || "";
  const duplicateFilter = searchParams.get("duplicate_filter") || "";
  const categoryFilter = searchParams.get("category_filter") || "";
  const search = searchParams.get("search") || "";
  const [selectedRange, setSelectedRange] = useState(initializeDateRange());
  const [data, setData] = useState({
    openCallUserModal: false,
    callUserData: [],
  });

  const userId = searchParams.get("user_id");

  const UpdateMainData = (type, value) => {
    let copy = data;
    copy[type] = value;
    setData({ ...copy });
  };

  useEffect(() => {
    if (singleSelectedQueueDetails?.result?.length > 0) {
      const pendingKeys = singleSelectedQueueDetails.result
        .map((e, i) => (e.status === "PENDING" ? i.toString() : null))
        .filter((key) => key !== null);
      setActiveKeys(pendingKeys);
    }
  }, [singleSelectedQueueDetails]);

  

  useEffect(() => {
    axios
      .get(`${api}/makecall/?user_id=${searchParams.get("user_id")}`)
      .then((res) => {
        UpdateMainData("callUserData", res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId]);

  const fetchTickets = async () => {
    if (escalatedTickets) {
      const offset = (currentPage - 1) * 10;
      try {
        const res = await axios.get(
          `${api}/escalated_tickets/?offset=${offset}&limit=10`
        );
        const tickets = res?.data?.result || [];
        setSingleSelectedQueueDetails(res?.data || {});
        if (tickets.length > 0) {
          const firstIssue = tickets[0];
          handleSelectChat(firstIssue);
          setTotalPages(res?.data?.total_tickets_count);
        }
        setRefresh(false);
        setRefreshTicketChat(false);
      } catch (err) {
        console.log(err);
        setRefresh(false);
        setRefreshTicketChat(false);
      }
    } else {
      const offset = (currentPage - 1) * 10;
      try {
        const res = await axios.get(
          `${api}/ticket_listing/?user_id=${searchParams.get(
            "user_id"
          )}&offset=${offset}&limit=10`
        );
        const tickets = res?.data?.result || [];
        setSingleSelectedQueueDetails(res?.data || {});
        if (tickets.length > 0) {
          const firstIssue = tickets[0];
          handleSelectChat(firstIssue);
          setTotalPages(res?.data?.all_tickets_count);
        }
        setRefresh(false);
      } catch (err) {
        console.log(err);
        setRefresh(false);
      }
    }
  };
  useEffect(() => {
    if (refresh || currentPage) {
      fetchTickets();
    }
  }, [api, searchParams, refresh, currentPage]);

  useEffect(() => {
    axios
      .get(
        `${api}/user_ticket/?offset=${
          (searchParams.get("current_page") - 1) * 50
        }&limit=50&sortBy=-created_at&duplicate_filter=${duplicateFilter}&search=${search}&status=${statusFilter}${
          my_tickets ? `&agent_id=${cms_id}` : ""
        }&category_filter=${categoryFilter}&start_date=${
          selectedRange?.startDate !== ""
            ? `${selectedRange?.startDate} 00:00:00`
            : ""
        }&end_date=${
          selectedRange?.endDate !== ""
            ? `${selectedRange?.endDate} 23:59:5`
            : ""
        } `
      )
      .then((res) => {
        SetAllTicketsIds(res?.data?.user_ids);
        setCurrentIndex(
          find_current_index(searchParams.get("user_id"), res?.data?.user_ids)
        );
      })
      .catch((e) => {
        console.log(e?.message);
      });
  }, [searchParams, my_tickets, cms_id, api]);

  function find_current_index(user_id, arr) {
    let index = arr.findIndex((e) => e == user_id);

    return index;
  }
  const raw_searchParams = GetSearchParams(["user_id"]);
  function move_to_user(index) {
    navigate({
      pathname: window.location.pathname,
      search: `user_id=${all_ticket_ids[index]}&${raw_searchParams}`,
    });
  }

  const getChatHistory = useCallback(
    async (selected_chat) => {
      try {
        const res = await axios.get(
          `${api}/chatsession/${selected_chat?.chat_session?.id}/?source=BOT&chat_subsession_id=${selected_chat?.chat_sub_session_id}`
        );
        setChatHistory(res?.data?.content?.message || []);
      } catch (err) {
        console.log(err);
      }
    },
    [api]
  );

  const handleSelectChat = useCallback(
    (issue) => {
      setSelectedChat(issue);
      getChatHistory(issue);
      if (issue?.escalated_type) {
        setSelectedEscalatedValue(issue?.escalated_type);
      }
    },
    [getChatHistory]
  );

  const handleToggle = (key, issue) => {
    if (activeKeys.includes(key)) {
      if (issue?.status === "ESCALATED") {
        // setEscalatedType(null);
        // setSelectedIssue({});
        setSelectedEscalatedValue(issue?.escalated_type);
        setSelectedIssue(issue);
      }
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      handleSelectChat(issue);
      setEscalatedType(issue?.escalated_type);
      setActiveKeys([...activeKeys, key]);
      // getTicketChat(issue?.id);
    }
  };

  const resolvePutCall = async () => {
    setIsApi(true);
    try {
      await axios.put(`${api}/tickets/${selectedChat?.id}/`, {
        status: "RESOLVED",
      });
      setSingleSelectedQueueDetails((prevState) => ({
        ...prevState,
        result: prevState.result.map((ticket) =>
          ticket.id === selectedChat.id
            ? { ...ticket, status: "RESOLVED" }
            : ticket
        ),
      }));
      setRefresh(true);
      setOpenModal(false);
    } catch (e) {
      console.log(e);
      setRefresh(false);
    } finally {
      setIsApi(false);
    }
  };

  async function postTicketNotes(ticketId) {
    try {
      const response = await axios.post(`${api}/ticket_notes/`, {
        note: notes,
        cs_agent_id: parseInt(cms_id),
        ticket_id: ticketId,
      });
      setNotes("");
      // fetchTickets();
      // getAllTicketNotes();
    } catch (e) {
      console.log(e);
    }
  }

  const paginatedResults = useMemo(
    () =>
      singleSelectedQueueDetails?.result?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [singleSelectedQueueDetails, currentPage, itemsPerPage]
  );

  // async function getSingleTicketChat(ticketId) {
  //   try {
  //     const response = await axios.get(`${api}/tickets/${ticketId}/`);
  //     return response?.data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  const updateEscalateApi = () => {
    setIsLoading(true);

    axios
      .put(`${api}/tickets/${selectedTicketId}/`, {
        // escalated_type:
        //   Object.keys(selectedIssue)?.length > 0
        //     ? selectedIssue?.escalated_type !== ""
        //       ? selectedIssue?.escalated_type
        //       : selectedEscalatedValue
        //     : selectedEscalatedValue,
        escalated_type: selectedEscalatedValue,
        status: "ESCALATED",
        escalated_note: notes,
        assigned_agent_id: parseInt(cms_id),
        agent_name: cms_username,
      })
      .then((res) => {
        setIsLoading(false);
        setSelectedEscalatedValue(null);
        setRefreshTicketChat(true);
        fetchTickets();
        setSelectedIssue({});
        setNotes("");

        setEscalatedModal(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  function renderStatusColorClassNames(status) {
    if (status === "PENDING") {
      return "accordion-header-ticket-pending";
    }

    if (
      status === "RAISED_TICKET" ||
      status === "USER_ACTION_REQUIRED" ||
      status === "USER_RESPONDED"
    ) {
      return "accordion-header-ticket-raised";
    }

    if (status === "ESCALATED") {
      return "accordion-header-ticket-escalated";
    }

    return "accordion-header-ticket-resolve";
  }

  function checkUserPermissions(issue) {
    if (issue?.status === "USER_ACTION_REQUIRED") {
      if (permissions.includes(VIEW_RAISED_TICKET)) {
        return true;
      }
    }

    if (issue?.status === "ESCALATED") {
      if (permissions.includes(VIEW_ESCALATED_TICKETS)) {
        return true;
      }
    }

    if (issue?.status === "PENDING") {
      if (permissions.includes(VIEW_PENDING_TICKETS)) {
        return true;
      }
    }

    if (permissions.includes(VIEW_QUEUE_TICKTES)) {
      return true;
    }

    return false;
  }

  const renderAccordion = () => (
    <Accordion
      activeKey={activeKeys}
      className="width-100"
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      {singleSelectedQueueDetails?.result?.map(
        (issue, index) =>
          checkUserPermissions() && (
            <Accordion.Item eventKey={String(index)} key={index}>
              <Accordion.Header
                className={`${renderStatusColorClassNames(
                  issue?.status
                )} common-accordion-header-ticket`}
                onClick={() => {
                  if (issue?.status === "ESCALATED") {
                    setEscalatedType(issue?.escalated_type);
                    setSelectedIssue(issue);
                    // setEscalatedModal(true);
                  }
                  handleToggle(String(index), issue);
                }}
              >
                <div
                  className="accordion-header-ticket-details"
                  style={{
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    className="flex-start"
                    style={{
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="font-weight-600"
                      style={{ fontSize: "12px" }}
                      // onClick={(e) => {

                      // }}
                    >
                      {issue?.issue_title || "No Issue Summary"}
                    </p>
                    <p className="font-weight-600" style={{ fontSize: "10px" }}>
                      ( {formatTimeStamp(issue?.created_at)} )
                    </p>
                    {issue?.is_duplicate && (
                      <p
                        className="font-weight-600"
                        style={{
                          fontSize: "12px",
                          letterSpacing: "1px",
                          background: "#091057",
                          padding: "4px",
                          borderRadius: "4px",
                        }}
                        // onClick={(e) => {

                        // }}
                      >
                        {"Duplicated"}
                      </p>
                    )}
                  </div>
                  <div
                    className="flex-center-center"
                    style={{
                      gap: "6px",
                    }}
                  >
                    {issue?.is_delegated && (
                      <p
                        className="status-name-ticket-details-changes font-weight-600 flex-center-center"
                        style={{
                          background:
                            "linear-gradient(135deg, #64b5f6 0%, #bbdefb 100%)",
                          // border: "1px solid white",
                          paddingTop: "2px",
                        }}
                      >
                        {issue?.is_delegated ? "Delegated" : ""}
                      </p>
                    )}
                    {(issue?.status === "RAISED_TICKET" ||
                      issue?.status === "ESCALATED" ||
                      issue?.status === "USER_RESPONDED" ||
                      issue?.status === "USER_ACTION_REQUIRED") &&
                      permissions.includes(ESCALATE_TICKET) && (
                        <p
                          className="status-name-ticket-details font-weight-600 flex-center-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedTicketId(issue?.id);
                            setSelectedIssue(issue);
                            // if (issue?.status === "ESCALATED") {
                            //   setEscalatedType(issue?.escalated_type);
                            // }
                            setEscalatedModal(true);
                          }}
                        >
                          {issue?.escalated_type
                            ? issue?.escalated_type
                            : "Escalate"}
                        </p>
                      )}
                    {/* {issue?.status === "ESCALATED" &&
                  selectedIssue?.id === issue?.id && (
                    <p
                      className="status-name-ticket-details font-weight-600 flex-center-center"
                      onClick={() => {
                        setSelectedIssue(issue);
                        setSelectedTicketId(issue?.id);
                        setEscalatedModal(true);
                      }}
                    >
                      {issue?.escalated_type}
                    </p>
                  )} */}

                    <p className="status-name-ticket-details-changes font-weight-600">
                      {renderStatusName(issue?.status)}
                    </p>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="flex-flex-start-flex-start flex-column accordion-body-ticket-main width-100">
                <SingleTicket
                  ticketChat={ticketChat}
                  issue={issue}
                  allTicketNotes={allTicketNotes}
                  notes={notes}
                  setNotes={setNotes}
                  postTicketNotes={postTicketNotes}
                  chatSessionId={singleSelectedQueueDetails?.chat_session_id}
                  user_id={singleSelectedQueueDetails?.user_id}
                  ticekt_id={issue.id}
                  setRefresh={setRefresh}
                  singleSelectedQueueDetails={singleSelectedQueueDetails}
                  fetchTickets={fetchTickets}
                  setRefreshTicketChat={setRefreshTicketChat}
                  refreshTicketChat={refreshTicketChat}
                  setError={setError}
                  error={error}
                  openEndchatModal={openEndchatModal}
                  setOpenEndchatModal={setOpenEndchatModal}
                />
              </Accordion.Body>
            </Accordion.Item>
          )
      )}
    </Accordion>
  );

  const renderEscalatedAccordion = () => (
    <Accordion
      activeKey={activeKeys}
      className="width-100"
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      {paginatedResults?.map((issue, index) => (
        <Accordion.Item eventKey={String(index)} key={index}>
          <Accordion.Header
            className={`${renderStatusColorClassNames(
              issue?.status
            )} common-accordion-header-ticket`}
            onClick={() => {
              if (issue?.status === "ESCALATED") {
                setEscalatedType(issue?.escalated_type);
                setSelectedIssue(issue);
                // setEscalatedModal(true);
              }
              handleToggle(String(index), issue);
            }}
          >
            <div
              className="accordion-header-ticket-details"
              style={{ color: "white", padding: "10px", borderRadius: "4px" }}
            >
              <p
                className="font-weight-600"
                style={{ fontSize: "12px" }}
                // onClick={(e) => {

                // }}
              >
                {issue?.issue_title || "No Issue Summary"}
              </p>
              <div
                className="flex-center-center"
                style={{
                  gap: "6px",
                }}
              >
                {(issue?.status === "RAISED_TICKET" ||
                  issue?.status === "ESCALATED" ||
                  issue?.status === "USER_RESPONDED" ||
                  issue?.status === "USER_ACTION_REQUIRED") && (
                  <p
                    className="status-name-ticket-details font-weight-600 flex-center-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedTicketId(issue?.id);
                      setSelectedIssue(issue);
                      // if (issue?.status === "ESCALATED") {
                      //   setEscalatedType(issue?.escalated_type);
                      // }
                      setEscalatedModal(true);
                    }}
                  >
                    {issue?.escalated_type ? issue?.escalated_type : "Escalate"}
                  </p>
                )}
                {/* {issue?.status === "ESCALATED" &&
                  selectedIssue?.id === issue?.id && (
                    <p
                      className="status-name-ticket-details font-weight-600 flex-center-center"
                      onClick={() => {
                        setSelectedIssue(issue);
                        setSelectedTicketId(issue?.id);
                        setEscalatedModal(true);
                      }}
                    >
                      {issue?.escalated_type}
                    </p>
                  )} */}

                <p className="status-name-ticket-details-changes font-weight-600">
                  {renderStatusName(issue?.status)}
                </p>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className="flex-flex-start-flex-start flex-column accordion-body-ticket-main width-100">
            <SingleTicket
              ticketChat={ticketChat}
              issue={issue}
              allTicketNotes={allTicketNotes}
              notes={notes}
              setNotes={setNotes}
              postTicketNotes={postTicketNotes}
              chatSessionId={singleSelectedQueueDetails?.chat_session_id}
              user_id={singleSelectedQueueDetails?.user_id}
              ticekt_id={issue.id}
              setRefresh={setRefresh}
              singleSelectedQueueDetails={singleSelectedQueueDetails}
              fetchTickets={fetchTickets}
              setRefreshTicketChat={setRefreshTicketChat}
              refreshTicketChat={refreshTicketChat}
              setError={setError}
              error={error}
              openEndchatModal={openEndchatModal}
              setOpenEndchatModal={setOpenEndchatModal}
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );

  return (
    <>
      {Object.keys(singleSelectedQueueDetails).length > 0 && (
        <div
          className="ticket-details-main-container flex-flex-start-flex-start width-100"
          style={{ gap: "16px", height: "calc(100vh - 108px)" }}
        >
          <div
            className="ticket-details-queue-left-section hide-scroll"
            style={{
              width: "calc(100% - 400px)",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <div
              className=" flex-space-between-center width-100"
              style={{ gap: "16px" }}
            >
              {!escalatedTickets && (
                <div className="flex-start top-header-ticket-details flex-center-center">
                  <div
                    onClick={() => {
                      setRefreshTickets(true);
                      backClickHandler();
                    }}
                    className="back-btn-ticket-details"
                  >
                    {"<-"}
                  </div>
                  <p className="font-weight-600">
                    {singleSelectedQueueDetails?.user_name}(
                    {singleSelectedQueueDetails?.user_id})
                    <FontAwesomeIcon
                      style={{ marginLeft: "10px" }}
                      onClick={() =>
                        redirectToPath(
                          `https://cms.smytten.com/UserDetail/${singleSelectedQueueDetails?.user_id}`
                        )
                      }
                      icon={faSquareArrowUpRight}
                    />
                  </p>
                  {permissions.includes(MAKE_CALL) && (
                    <button
                      className="call-user-btn flex-center-center font-weight-600"
                      onClick={() => {
                        UpdateMainData("openCallUserModal", true);
                      }}
                    >
                      CALL USER{" "}
                      <span className="font-10px">
                       
                         <FontAwesomeIcon icon={faPhone} />
                        {/* <i className="fas fa-phone"></i> */}
                      </span>
                    </button>
                  )}

                  <div className="tool-tip-container">
                    <Tooltip
                      title={
                        data?.callUserData?.agent ? (
                          callDetailsToolTipContent(data)
                        ) : (
                          <p className="tool-tip-text-ticket flex-center-center flex-column">
                            No data
                          </p>
                        )
                      }
                      placement="top"
                      color={"#166184"}
                      defaultOpen={false}
                      className="tool-tip-text-container"
                    >
                      <p className="info-icon-ticket">
                        {InfoICON("#166184", "white", "32", "32")}
                      </p>
                    </Tooltip>
                  </div>
                  <p className="font-weight-600">
                    Chat session {singleSelectedQueueDetails?.chat_session_id}
                    <FontAwesomeIcon
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        const openChats =
                          singleSelectedQueueDetails?.result?.filter(
                            (e) => e?.status === "PENDING"
                          );
                        const closeChats =
                          singleSelectedQueueDetails?.result?.filter(
                            (e) => e?.status === "RESOLVED"
                          );
                        if (searchParams.get("ticket_user_id")) {
                          redirectToPath(
                            `/chats/${singleSelectedQueueDetails?.chat_session_id}?name=${singleSelectedQueueDetails?.user_name}&user_id=${singleSelectedQueueDetails?.user_id}&ticket_view=true&ticket_user_id=${singleSelectedQueueDetails?.user_id}`
                          );
                        } else if (searchParams.get("user_id")) {
                          redirectToPath(
                            `/chats/${singleSelectedQueueDetails?.chat_session_id}?name=${singleSelectedQueueDetails?.user_name}&user_id=${singleSelectedQueueDetails?.user_id}&ticket_view=true`
                          );
                        }
                      }}
                      icon={faSquareArrowUpRight}
                    />
                  </p>
                </div>
              )}
              <div className="issue-title-ticket-details flex-start">
                <FontAwesomeIcon
                  onClick={() => {
                    if (curr_index - 1 >= 0) {
                      move_to_user(curr_index - 1);
                    }
                  }}
                  className="cursor-p"
                  icon={faAngleLeft}
                  style={{ color: curr_index < 1 ? "#f5f5f5" : "black" }}
                />
                <FontAwesomeIcon
                  className="cursor-p ml-10px"
                  onClick={() => {
                    if (curr_index + 1 < all_ticket_ids.length) {
                      move_to_user(curr_index + 1);
                    }
                  }}
                  style={{
                    color:
                      curr_index === all_ticket_ids.length - 1
                        ? "#f5f5f5"
                        : "black",
                  }}
                  icon={faAngleRight}
                />
              </div>
            </div>
            <div className="flex width-100 mt-10px flex-column">
              {escalatedTickets
                ? renderEscalatedAccordion()
                : renderAccordion()}
            </div>
            <div
              className="width-100 flex-center-center"
              style={{ marginTop: "30px" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalPages / 10)}
                onPageChange={(e) => setCurrentPage(e)}
              />
            </div>
          </div>
          <div
            className="ticket-details-queue-right-section"
            style={{
              width: "400px",
              height: "calc(100vh - 122px)",
              background: "#fafafa",
            }}
          >
            <ChatInterface
              allParams={{ sender_type: "USER" }}
              r_chat_history={chatHistory}
              parentchatContainerRef={parentchatContainerRef}
              toggleOptions={false}
              mytickets={true}
            />
          </div>

          {openModal && (
            <ConfirmationModal
              show={openModal}
              onHide={() => setOpenModal(false)}
              saveClickHandler={resolvePutCall}
              api={isApi}
              title={"Are you sure to resolve?"}
            />
          )}
        </div>
      )}

      {escalatedModal && (
        <TicketRaiseModal
          show={escalatedModal}
          onHide={() => {
            setEscalatedModal(false);
            setSelectedIssue({});
            setNotes("");
          }}
          options={escalatedOptions}
          escalated={true}
          handleChangeFunc={(value) => {
            setSelectedEscalatedValue(value);
          }}
          selectedStatus={selectedEscalatedValue}
          // selectedStatus={selectedEscalatedValue}
          setSelectedStatus={setSelectedEscalatedValue}
          onClickSubmitHandler={() => {
            if (!isLoading) {
              updateEscalateApi();
            }
          }}
          notes={
            Object.keys(selectedIssue)?.length > 0
              ? selectedIssue?.escalated_notes
              : notes
          }
          setNotes={setNotes}
        />
      )}
      {data?.openCallUserModal && (
        <CallUserModal
          show={data?.openCallUserModal}
          onHide={() => UpdateMainData("openCallUserModal", false)}
          userId={singleSelectedQueueDetails?.user_id}
        />
      )}
      {/* {openCategoryDisposition && (
        <EndChatModal
          show={openCategoryDisposition}
          onHide={() => setOpenCategoryDisposition(false)}
        />
      )} */}
    </>
  );
}
