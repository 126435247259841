import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./ConfirmationModal.css";
import "../chat-modal/ChatModal.css";
import { crossBlack } from "../../../utils/SvgIcons";

function ConfirmationModal(props) {
  const {
    show,
    onHide,
    saveClickHandler,
    api,
    title,
    inputValue,
    setInputValue,
    isTextField,
  } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="chat-modal-main-container flex-column">
        <div className="are-you-sure-confirm-wrapper flex-column">
          <div className="flex-space-between">
            <p>{title ? title : "Are you sure to reassign all the chats"}</p>
            <span className="cursor-p" onClick={onHide}>
              {crossBlack}
            </span>
          </div>
          {isTextField && (
            <div>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter your message here..."
                className="conversation-notes-input width-100"
              />
            </div>
          )}
          <div className="flex-felx-end-center btn-confirm-send-cancel-modal">
            <button onClick={() => onHide()}>Cancel</button>
            <button
              onClick={() => {
                if (!api) {
                  saveClickHandler();
                }
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
