import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { crossBlack } from "../../../utils/SvgIcons";
import "./CallUserModal.css";
import CallIcon from "@mui/icons-material/Call";
import { useSearchParams } from "react-router-dom";
import { cms_api } from "../../chat-bot/AiAssistanceBot";
import axios from "axios";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { get_api } from "../../../utils/LocalFunctions";
import { Spinner } from "react-bootstrap";
import { formatElapsedTime } from "../../../utils";

function CallUserModal(props) {
  const { show, onHide, chat, chatSubsessionId, chatSessionId } = props;
  const [searchParams] = useSearchParams();
  const api = cms_api;
  const user_id = searchParams.get("user_id");
  const [data, setData] = useState({
    openCallUserModal: false,
    userDetails: {},
    isEditable: false,
    phoneNumber: "",
    message: "",
    callId: null,
    error: "",
    api: false,
    userCallAction: "",
    cms_phone_number: null,
    calling_reason: "",
    callStartTime: null,
    checkTime: false,
    isGetCall: true,
  });
  const sessionId = localStorage.getItem("cms_session_id");
  const url = get_api();
  const inputRef = useRef(null);
  const cms_id = localStorage.getItem("cms_id");
  const intervalRef = useRef(null);

  const UpdateMainData = (type, value) => {
    let copy = data;
    copy[type] = value;
    setData({ ...copy });
  };



  useEffect(() => {
    axios
      .get(`${api}/discover_user/cms/users/details/${user_id}/`, {
        headers: {
          session_id: sessionId,
        },
      })
      .then((res) => {
        const { content } = res.data;
        UpdateMainData("userDetails", content);
        UpdateMainData("phoneNumber", content?.phone);
        UpdateMainData("cms_phone_number", content?.phone);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

 
  function checkAgentUserCall(callAction) {
    const destination = callAction?.destination;
    const status = callAction?.state;
    const length = callAction?.destination.split(",")?.length;

    const updatedCallAction = { ...callAction };

    if (
      (destination === "" && status === "Ringing") ||
      (length === 1 && status === "Answered")
    ) {
      updatedCallAction.state = `Agent ${updatedCallAction?.state}`;
      UpdateMainData("userCallAction", updatedCallAction);
    } else if (status === "Down" && destination === "") {
      UpdateMainData("userCallAction", updatedCallAction);
    } else {
      updatedCallAction.state = `User ${updatedCallAction?.state}`;

      if (updatedCallAction.state === "User Answered" && !data?.checkTime) {
        UpdateMainData("callStartTime", new Date().getTime());
        UpdateMainData("checkTime", true);
      }

      UpdateMainData("userCallAction", updatedCallAction);
    }
  }

  const getCallUser = async () => {
    if (data?.callId) {
      try {
        const response = await axios.get(
          `https://api-smartflo.tatateleservices.com/v1/live_calls?call_id=${data?.callId}`,
          {
            headers: {
              Authorization:
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0NTMzMjEiLCJpc3MiOiJodHRwczovL2Nsb3VkcGhvbmUudGF0YXRlbGVzZXJ2aWNlcy5jb20vdG9rZW4vZ2VuZXJhdGUiLCJpYXQiOjE3MzE0MDY5NjcsImV4cCI6MjAzMTQwNjk2NywibmJmIjoxNzMxNDA2OTY3LCJqdGkiOiJCTVF0b2N3NHJmajc2cE9VIn0.pLR5-BCp6aWySau46ocS7y3nggHhGGvHffw1uC5h8BU",
            },
          }
        );

        if (Array.isArray(response?.data) && response.data.length === 0) {
          await endCall();
          stopPolling();
        } else {
          const callAction = response?.data[0];

          checkAgentUserCall(callAction);
        }
        UpdateMainData("isGetCall", false);
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };

  const startPolling = () => {
    if (data?.isGetCall) {
      setTimeout(() => {
        intervalRef.current = setInterval(getCallUser, 2000);
      }, 5000);
    } else {
      intervalRef.current = setInterval(getCallUser, 2000);
    }
  };

  const stopPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (data.callId) {
      startPolling();
    }

    return () => {
      stopPolling(); // Stop polling when the component is unmounted
    };
  }, [data.callId]);

  useEffect(() => {
    if (data.isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [data.isEditable]);

  const handleEditClick = () => {
    UpdateMainData("isEditable", !data.isEditable);
  };

  const handlePhoneNumberChange = (e) => {
    UpdateMainData("phoneNumber", e.target.value);
  };
  const handleCallingReason = (e) => {
    UpdateMainData("calling_reason", e.target.value);
  };


  const postUserCall = async () => {
    UpdateMainData("api", true);
    UpdateMainData("userCallAction", "");
    UpdateMainData("callStartTime", null);
    UpdateMainData("isGetCall", true);
    try {
      const response = await axios.post(`${url}/makecall/`, {
        agent_id: parseInt(cms_id),
        user_number: parseInt(data.phoneNumber),
        cms_user_number: parseInt(data?.cms_phone_number),
        section: chat ? "SECTION" : "TICKET",
        user_id: parseInt(user_id),
        remark: data?.calling_reason,
        chat_sub_session_id: chat && parseInt(chatSubsessionId),
        chat_session_id: chat && parseInt(chatSessionId),
      });
      UpdateMainData("api", false);
      if (response.status === 200) {
        UpdateMainData("message", response?.data?.message);

        if (response?.data?.call_id) {
          setData((prevData) => {
            const updatedData = { ...prevData, callId: response.data.call_id };

            startPolling(); // Trigger polling within the callback
            return updatedData;
          });
        } else {
          UpdateMainData("error", "Call ID is missing in response");
        }
      } else {
        UpdateMainData("error", "Call Failed Retry");
      }
    } catch (e) {
      UpdateMainData("error", "Call Failed Retry");
      UpdateMainData("api", false);
      console.log(e);
    }
  };

  const endCall = async () => {
    let elapsedTime = 0;

    if (data?.callStartTime) {
      const currentTime = new Date().getTime();
      elapsedTime = Math.floor((currentTime - data?.callStartTime) / 1000); // Time in seconds
    }

    if (data?.callId && data?.userCallAction && data?.userCallAction?.state) {
      try {
        await axios
          .put(
            `${url}/makecall/`,
            {
              call_id: data.callId,
              state: data.userCallAction?.state,
              call_duration: formatElapsedTime(elapsedTime),
              // call_duration: data.userCallAction?.call_time,
            },
            {
              headers: {
                Authorization:
                  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0NTMzMjEiLCJpc3MiOiJodHRwczovL2Nsb3VkcGhvbmUudGF0YXRlbGVzZXJ2aWNlcy5jb20vdG9rZW4vZ2VuZXJhdGUiLCJpYXQiOjE3MzE0MDY5NjcsImV4cCI6MjAzMTQwNjk2NywibmJmIjoxNzMxNDA2OTY3LCJqdGkiOiJCTVF0b2N3NHJmajc2cE9VIn0.pLR5-BCp6aWySau46ocS7y3nggHhGGvHffw1uC5h8BU",
              },
            }
          )
          .then((res) => {
            if (
              data?.userCallAction?.state === "Agent Ringing" ||
              data?.userCallAction?.state === "User Ringing"
            ) {
              UpdateMainData("userCallAction", { state: "Call Rejected" });
            } else {
              UpdateMainData("userCallAction", { state: "Call Ended" });
            }
            UpdateMainData("callStartTime", null);
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };

  function renderCallStatus() {
    if (Object.keys(data?.userCallAction).length > 0) {
      return (
        <p
          className="call-message font-weight-600 font-16px"
          style={{
            color:
              ["Call Ended", "Call Rejected"].includes(
                data?.userCallAction?.state
              ) && "red",
          }}
        >
          {data?.userCallAction?.state}
        </p>
      );
    }

    if (data?.message) {
      return (
        <p className="call-message font-weight-600 font-16px">
          {data?.message}
        </p>
      );
    }

    if (data?.error) {
      return <p className="call-error">{data?.error}</p>;
    }

    return <p></p>;
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={() => {
        stopPolling();
        onHide();
      }}
    >
      <div className="call-user-container flex-column">
        <p className="flex-flex-end-center" onClick={onHide}>
          {crossBlack}
        </p>
        <div className="call-details font-16px font-weight-600">
          Call User : {`${data.userDetails?.name} (${data.userDetails?.id})`}
        </div>

        <div className="phone-number-section flex-start flex-flex-start-center mt-10px">
          <label>Phone Number: </label>
          <div className="user-phone-number">
            <input
              type="text"
              value={data.phoneNumber}
              onChange={handlePhoneNumberChange}
              readOnly={!data.isEditable}
              ref={inputRef}
              className={`phone-input ${data.isEditable ? "editable" : ""}`}
            />
            {!data.isEditable && (
              <div className="edit-icon-call">
                <BorderColorIcon
                  onClick={handleEditClick}
                  style={{ fontSize: "16px" }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="phone-number-section flex-start flex-flex-start-center mt-10px">
          <label>Calling Reason : </label>
          <div
            className="user-phone-number"
            style={{
              border: "none",
            }}
          >
            {/* <input
              type="text"
              value={data.calling_reason}
              onChange={handleCallingReason}
            /> */}
            <textarea
              value={data.calling_reason}
              onChange={handleCallingReason}
              rows="2" // Number of visible text lines
              cols="20" // Width of the textarea
              style={{
                resize: "both",
                outline: "none",
                border: "1px solid #dadada",
              }} // Optional: Allows resizing the textarea
            />
          </div>
        </div>

        <div className="flex-space-between mt-20px">
          {renderCallStatus()}
          <div
            className="flex-flex-start-center"
            style={{
              gap: "5px",
            }}
          >
            {data?.api && (
              <Spinner
                size={"sm"}
                className=""
                animation="border"
                style={{ color: "#8369fc" }}
              />
            )}

            <button
              className="call-user-btn flex-center-center font-weight-600"
              onClick={() => {
                if (!data?.api) {
                  postUserCall();
                }
              }}
            >
              CALL USER{" "}
              <span className="font-10px">
                <CallIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CallUserModal;
