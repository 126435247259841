import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "../end-chat-modal/EndChatModal.css";
import { crossBlack } from "../../../utils/SvgIcons";

export default function TextModal(props) {
  const { onHide, title, apiCall } = props;
  const [user_message, setUserMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!user_message) {
      newErrors.user_message = "User message is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      apiCall({ user_message });
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="end-chat-modal-modified-content">
        <div className="flex-space-between pb-8px">
          <p className="font-weight-600">{title ? title : "Send Message"}</p>
          <p onClick={onHide} className="cursor-p">
            {crossBlack}
          </p>
        </div>
        {
          <label className="width-100 mb-20px">
            <input
              type="text"
              className="width-100  conversation-notes-input"
              placeholder="Enter Message"
              value={user_message}
              onChange={(e) => {
                setUserMessage(e.target.value);
              }}
            />
            {errors.user_message && user_message === "" && (
              <p className="error-message">{errors.user_message}</p>
            )}
          </label>
        }
        <div className="buttons-container flex-flex-end-center">
          <button className="cancel-btn" onClick={onHide}>
            Cancel
          </button>
          <button onClick={handleSubmit} className="chat-completed-btn">
            {"Send"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
