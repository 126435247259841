import React from "react";
import { right_svg } from "../../../utils/SvgIcons";
import { RenderHtml } from "../../../utils/LocalFunctions";
import { formatTimeStamp } from "../../../utils";
import { renderChatOptionsToggle } from "../../../utils/utils";

export default function ORDER_LIST({
  message,
  click_enable,
  optionClickHandler,
  selected_options,
  latest,
  bot_type,
  toggleOptions,
}) {
  // function redirectOrderList(option) {
  //   const orderTitle = option?.order_title;
  //   const arr = orderTitle?.split(":")?.map((str) => str.trim());
  //   const id = arr?.length ===1 && arr[1];
  //   if (option?.order_type === "SHOP") {
  //     return `https://cms.smytten.com/ShopDetail/${id.slice(1)}`;
  //   } else if (option?.order_type === "TRIAL") {
  //     return `https://cms.smytten.com/TrialDetail/${id.slice(1)}`;
  //   }
  // }

  function redirectOrderList(option) {
    if (!option) return;

    const { order_title, order_type } = option;
    const id = order_title?.split(":")?.[1]?.trim()?.slice(1);

    if (!id) return;

    const baseUrls = {
      SHOP: "https://cms.smytten.com/ShopDetail/",
      TRIAL: "https://cms.smytten.com/TrialDetail/",
    };

    return baseUrls[order_type] ? `${baseUrls[order_type]}${id}` : undefined;
  }

  return (
    <div className="width-100">
      <p className="message-text">{RenderHtml(message?.message)}</p>
      {renderChatOptionsToggle(bot_type, toggleOptions, message?.sender_type) &&
        message?.options?.map((e) => {
          return (
            <a
              href={redirectOrderList(e)}
              target="_blank"
              rel="noreferrer"
              class={`${message?.sender_type !== "USER" && "disabled-link"}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              <div
                onClick={() => {
                  if (click_enable) {
                    optionClickHandler({
                      selection_type: message.selection_type,
                      message_type: message.type,
                      text: e.order_title,
                      ...e,
                    });
                  }
                }}
                className="option-container flex-flex-start-flex-start flex-column mt-12px"
              >
                <p className="order_title_text text-left">{e?.order_title}</p>
                <div
                  style={{ gap: "24px" }}
                  className="flex-space-between-center width-100 mt-12px"
                >
                  <p
                    style={{ color: e?.message_color }}
                    className="order-status-text"
                  >
                    {e?.order_status}
                  </p>
                  <p className="order-delivery-text">{e?.edd_text}</p>
                </div>
                <div className="partision mt-12px mb-12px"></div>
                <div className="product-image-container hide-scroll">
                  {e?.images?.map((e) => {
                    return <img className="product-image" src={e} alt="" />;
                  })}
                </div>
                {selected_options.find((o) => o?.text === e?.text) &&
                  latest && <div className="right_svg_icon">{right_svg}</div>}
              </div>
            </a>
          );
        })}
      <p className="chat-time-stamp">{formatTimeStamp(message?.timestamp)}</p>
    </div>
  );
}
