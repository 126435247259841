export const formatTimeStamp = (timestamp) => {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Include seconds in the options
  const options = { hour: "numeric", minute: "numeric", second: "numeric", hour12: true };
  const time = date.toLocaleTimeString("en-US", options);

  const formattedDate = `${day}/${month}/${year}`;
  const formattedDateTime = `${formattedDate} ${time}`;

  return formattedDateTime;
};

export const formatElapsedTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;
  
  // Return time in HH:MM:SS format, with zero padding if necessary
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
