import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Toaster.css";
import loadergif from "../../images/toasteranimation.gif"

export default function Toaster({
  messages,
  onCloseFunc,
  width,
  financeToast,
  addQuestionToast,
}) {
  useEffect(() => {
    toast(
      <div className="flex-flex-start-center">
        <div
          style={{
            minWidth: "80px",
            minHeight: "90px",
            maxWidth: "80px",
            maxHeight: "90px",
            // width:'90%',
            // height:'90%'
          }}
        >
          <img src={loadergif} alt="" className="width-100 height-100" />
        </div>
        <div className="my-toast-body">
          {messages.map((e, i) => (
            <p key={i}>{e}</p>
          ))}
        </div>
      </div>
    );
  }, []);

  return (
    <div>
      <ToastContainer
        position={`${
          financeToast || addQuestionToast ? "top-center" : "bottom-center"
        }`}
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className={"my-toast-container"}
        bodyClassName={"my-toast-container-body"}
        style={{
          minWidth: `${width ? width : "400px"}`,
          minHeight: "96px",
        }}
        onClose={onCloseFunc ? onCloseFunc : () => {}}
      />
    </div>
  );
}
