import React, { useState, useEffect, useContext } from "react";
import "../my-chats/MyChats.css";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import { get_api, removeItemOnce } from "../../utils/LocalFunctions";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import MaterialUITable from "../../components/material-ui-table/MaterialUiTable";
import { formatTimeStamp } from "../../utils";
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import { RefreshContext } from "../../context/RefreshTicketsContext";
import { lightGradients } from "./AllTickets";
import {
  capsLetter,
  dateRanges,
  formatDateToMonthDayYear,
  ticketCategoryColorCodes,
  ticketCategoryList,
} from "../../utils/utils";
import { formatDateRange } from "../all-chats/AllChats";
import moment from "moment";
import { clear_all_svg, filterbydate } from "../../utils/SvgIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/pagination/Pagination";
import CreateTicket from "../../components/modals/create-ticket/CreateTicket";

export const render_category_remarks = (obj) => {
  return (
    <div style={{ gap: "8px" }} className="flex-flex-start-center">
      {obj.slice(0, 1)?.map((e, index) => (
        <div
          key={index}
          style={{
            background: ticketCategoryColorCodes[e]
              ? ticketCategoryColorCodes[e]
              : ticketCategoryColorCodes[1],
            color: "white",
            padding: "5px",
            borderRadius: "4px",
            fontSize: "12px",
          }}
          className="font-weight-600"
        >
          {capsLetter(e).replaceAll("_", " ")}
        </div>
      ))}
      {obj.length > 1 && (
        <div
          style={{
            background: "grey",
            color: "white",
            padding: "5px",
            borderRadius: "4px",
          }}
          className="font-weight-600"
        >
          +{obj.length - 1}
        </div>
      )}
    </div>
  );
};

function AgentAssignedTickets() {
  const initializeDateRange = () => {
    const dateRangeParam = searchParams.get("date_range");
    if (dateRangeParam && dateRangeParam !== "") {
      const { start_date, end_date } = formatDateRange(dateRangeParam, "");
      return {
        startDate: start_date || todaysDate,
        endDate: end_date || todaysDate,
      };
    }
    return {
      startDate: "",
      endDate: "",
    };
  };
  const [searchParams] = useSearchParams();
  const maxDate = moment().format("YYYY-MM-DD");
  const todaysDate = maxDate;
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [myTicketsData, setMyTicketsData] = useState([]);
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("current_page")
      ? parseInt(searchParams.get("current_page"))
      : 1
  );
  const [totalPages, setTotalPages] = useState(
    searchParams.get("current_page")
      ? parseInt(searchParams.get("current_page"))
      : 1
  );
  const [selectedSidebar, setSelectedSidebar] = useState(
    parseInt(localStorage.getItem("ticket_sidebar")) || 1
  );
  const cms_id = localStorage.getItem("cms_id");
  const [initialDateRange, setInitialDaterange] = useState(
    initializeDateRange()
  );
  const [selectedRange, setSelectedRange] = useState(initializeDateRange());

  const [statusFilter, setStatusFilter] = useState(
    searchParams.get("status_filter")
      ? searchParams.get("status_filter").split(",")
      : ["ESCALATED", "USER_RESPONDED", "PENDING"]
  );

  const [create_ticket, setCreateTicket] = useState(false);

  const api = get_api();
  const { refreshTickets, setRefreshTickets } = useContext(RefreshContext);

  const navigate = useNavigate();
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);

  const [dateFilterText, setDateFilterText] = useState(() => {
    const dateRangeParam = searchParams.get("date_range");
    if (dateRangeParam && dateRangeParam !== "") {
      return dateRangeParam;
    }
    return "Filter Date Created";
  });

  const [categoryFilter, setCategoryFilter] = useState(
    searchParams.get("category_filter")
      ? searchParams.get("category_filter").split(",")
      : []
  );

  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);

  useEffect(() => {
    const { start_date, end_date } = formatDateRange(
      searchParams.get("date_range") ? searchParams.get("date_range") : "",
      ""
    );

    setSelectedRange({ endDate: end_date, startDate: start_date });
    setInitialDaterange({ endDate: end_date, startDate: start_date });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${api}/user_ticket/?agent_id=${cms_id}&offset=${
          (currentPage - 1) * 50
        }&limit=50&status=${statusFilter}&category=${categoryFilter}&sortBy=created_at&start_date=${
          selectedRange?.startDate !== ""
            ? `${selectedRange?.startDate} 00:00:00`
            : ""
        }&end_date=${
          selectedRange?.endDate !== ""
            ? `${selectedRange?.endDate} 23:59:5`
            : ""
        }`
      )
      .then((res) => {
        setMyTicketsData(res?.data?.result);
        setTotalPages(res?.data?.total_result_count);
        setRefreshTickets(false);
      })
      .catch((e) => {
        console.log(e?.message);
      });
  }, [
    refreshTickets,
    currentPage,
    statusFilter,
    selectedRange?.startDate,
    selectedRange?.endDate,
    categoryFilter,
  ]);

  useEffect(() => {
    const newParams = new URLSearchParams();

    if (dateFilterText !== "Filter Date Created") {
      newParams.set("date_range", dateFilterText);
    }

    if (statusFilter.length > 0) {
      newParams.set("status_filter", statusFilter.join(","));
    }
    if (categoryFilter.length > 0) {
      newParams.set("category_filter", categoryFilter.join(","));
    }

    newParams.set("current_page", currentPage.toString());

    navigate({
      pathname: window.location.pathname,
      search: newParams.toString(),
    });
  }, [statusFilter, currentPage, dateFilterText, categoryFilter]);

  useEffect(() => {
    const sidebar = localStorage.getItem("ticket_sidebar");
    setSelectedSidebar(parseInt(sidebar));
  }, [refreshTickets]);

  const render_remarks = (obj) => {
    const filteredKeys = Object.keys(obj).filter(
      (e) => !["RESOLVED", "PENDING"].includes(e)
    );

    return (
      <div style={{ gap: "8px" }} className="flex-flex-start-center">
        {filteredKeys.slice(0, 1).map((e, index) => (
          <div
            key={index}
            style={{
              background: lightGradients[e]
                ? lightGradients[e]
                : lightGradients[1],
              color: "white",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            {capsLetter(e).replaceAll("_", " ")}
          </div>
        ))}
        {filteredKeys.length > 1 && (
          <div
            style={{
              background: "grey",
              color: "white",
              padding: "5px",
              borderRadius: "4px",
            }}
            className="font-weight-600"
          >
            +{filteredKeys.length - 1}
          </div>
        )}
      </div>
    );
  };

  const changeSelectedRange = (e) => {
    let startDate = moment(new Date(e["startDate"])).format("YYYY-MM-DD");
    let endDate = moment(new Date(e["endDate"])).format("YYYY-MM-DD");
    setSelectedRange({
      startDate: startDate,
      endDate: endDate,
    });
  };

  const saveDateClickHandler = () => {
    setInitialDaterange({
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate,
    });
    const newDateFilterText = `${formatDateToMonthDayYear(
      selectedRange.startDate
    )} -> ${formatDateToMonthDayYear(selectedRange.endDate)}`;
    setDateFilterText(newDateFilterText);
    setIsDateDropdownOpen(false);
  };

  function renderFilterByDateContent(type) {
    return (
      <div
        className="height-100"
        style={{
          marginRight: !type && "10px",
        }}
      >
        <Dropdown
          align={"end"}
          id={
            type
              ? "survey-date-filter-dropdown-daily-chats"
              : "survey-date-filter-dropdown"
          }
          className="survey-date-filter"
          show={isDateDropdownOpen}
          onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
        >
          <Dropdown.Toggle className="width-100 height-100" style={{}}>
            <div className="flex-center-center toggle-text-filter">
              {filterbydate(dateFilterText)}
              {dateFilterText}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            <div className="date-filter-calender-container">
              {isDateDropdownOpen && (
                <DateRangePicker
                  open={isDateDropdownOpen}
                  onChange={(e) => changeSelectedRange(e)}
                  definedRanges={dateRanges}
                  initialDateRange={initialDateRange}
                  maxDate={todaysDate}
                />
              )}

              <div
                style={{ padding: "0px 10px" }}
                className="flex-space-between-center"
              >
                <p
                  onClick={() => {
                    setDateFilterText("Filter Date Created");
                    setIsDateDropdownOpen(false);
                    setInitialDaterange({
                      startDate: todaysDate,
                      endDate: todaysDate,
                    });
                    setSelectedRange({
                      startDate: todaysDate,
                      endDate: todaysDate,
                    });
                  }}
                  className="flex-flex-start-center clear-all-2C66D7 cursor-p"
                >
                  <span>{clear_all_svg()}</span>
                  Clear All
                </p>
                <p
                  onClick={() => {
                    saveDateClickHandler();
                  }}
                  className="clear-all-2C66D7 cursor-p"
                >
                  Save
                </p>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  const renderallStatus = () => {
    let alltypes = [
      "USER_ACTION_REQUIRED",
      "ESCALATED",
      "USER_RESPONDED",
      "PENDING",
    ];
    return alltypes.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            setCurrentPage(1);
            if (statusFilter.includes(e)) {
              setStatusFilter([...removeItemOnce(statusFilter, e)]);
            } else {
              setStatusFilter([...statusFilter, e]);
            }
          }}
        >
          {statusFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e).replaceAll("_", " ")}
        </p>
      );
    });
  };

  function clearAllFilters(filters, setFilters) {
    return (
      <p
        className="single-status-filter"
        onClick={() => {
          setCurrentPage(1);
          setFilters([]);
        }}
        style={{
          width: "fit-content",
          textWrap: "nowrap",
        }}
      >
        {filters?.length === 0 ? (
          <FontAwesomeIcon className="mr-8px" icon={faCheck}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            className="mr-8px"
            style={{ visibility: "hidden" }}
            icon={faCheck}
          ></FontAwesomeIcon>
        )}
        {"Clear all"}
      </p>
    );
  }

  function filterByStatusContent() {
    return (
      <div>
        <Dropdown
          className="survey-date-filter"
          id="survey-status-filter-dropdown"
          show={isStatusDropDownOpen}
          onToggle={(isOpen) => setIsStatusDropDownOpen(isOpen)}
        >
          <Dropdown.Toggle>
            <div className="flex-center-center toggle-text-filter">
              {statusFilter.length > 0 ? (
                <p className="dot-filter mr-10px"></p>
              ) : null}
              Filter by Status{" "}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            {clearAllFilters(statusFilter, setStatusFilter)}
            {renderallStatus()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  const renderallCategories = () => {
    return ticketCategoryList.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            setCurrentPage(1);
            if (categoryFilter.includes(e)) {
              setCategoryFilter([...removeItemOnce(categoryFilter, e)]);
            } else {
              setCategoryFilter([...categoryFilter, e]);
            }
          }}
          style={{
            width: "fit-content",
            textWrap: "nowrap",
          }}
        >
          {categoryFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e).replaceAll("_", " ")}
        </p>
      );
    });
  };

  function filterByCategory() {
    return (
      <div>
        <Dropdown
          className="survey-date-filter"
          id="survey-status-filter-dropdown"
          show={isCategoryDropdownOpen}
          onToggle={(isOpen) => setIsCategoryDropdownOpen(isOpen)}
        >
          <Dropdown.Toggle>
            <div className="flex-center-center toggle-text-filter">
              {categoryFilter.length > 0 ? (
                <p className="dot-filter mr-10px"></p>
              ) : null}
              Filter by Category{" "}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            {clearAllFilters(categoryFilter, setCategoryFilter)}
            {renderallCategories()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function agentTickets() {
    return (
      <div
        className="flex-column"
        style={{
          gap: "12px",
        }}
      >
        <div
          className="flex-space-between-center"
          style={{
            gap: "12px",
          }}
        >
          <div
            onClick={() => {
              setCreateTicket(true);
            }}
            className="purple-btn"
          >
            + Add Ticket
          </div>
          <div
            style={{
              gap: "12px",
            }}
            className="flex-flex-start-center"
          >
            {filterByCategory()}
            {filterByStatusContent()}
            {renderFilterByDateContent()}
            <div className="flex-center-center font-weight-600">
              Total Tickets : {totalPages}
            </div>
          </div>
        </div>
        <MaterialUITable
          columns={[
            {
              key: "user_id",
              label: "User Id",
            },
            {
              key: "user_name",
              label: "User Name",
            },
            {
              key: "pending_tickets",
              label: "Pending tickets",
            },
            {
              key: "first_ticket_date",
              label: "first Ticket date ",
              formatter: (value) => {
                return <div>{formatTimeStamp(value)}</div>;
              },
            },
            {
              key: "last_ticket_date",
              label: "Last ticket date",
              formatter: (value) => {
                return <div>{formatTimeStamp(value)}</div>;
              },
            },
            {
              key: "unique_ticket_categories",
              label: "Categories",
              formatter: (value) => {
                return <div>{render_category_remarks(value)}</div>;
              },
            },
            {
              key: "status_counts",
              label: "Remarks",
              formatter: (value) => {
                return <div>{render_remarks(value)}</div>;
              },
            },
          ]}
          data={myTicketsData}
          rowClickHandler={(row) => {
            setSelectedTicketId(row?.user_id);
            navigate(
              `/tickets?user_id=${row?.user_id}&ticket_user_id=${
                row?.user_id
              }&status_filter=${statusFilter}&category_filter=${categoryFilter}&current_page=${currentPage}&date_range=${
                dateFilterText === "Filter Date Created"
                  ? ""
                  : `${dateFilterText}`
              }`
            );
          }}
          rowsShowPerPage={10}
        />
      </div>
    );
  }

  return (
    <div className="width-100">
      {selectedSidebar === 1 && agentTickets()}

      <div className="width-100 flex-center-center mt-24px">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(totalPages / 50)}
          onPageChange={(e) => setCurrentPage(e)}
        />
      </div>

      {create_ticket && (
        <CreateTicket
          show={create_ticket}
          onHide={() => {
            setCreateTicket(false);
          }}
        />
      )}
    </div>
  );
}

export default AgentAssignedTickets;
