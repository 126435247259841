import React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

function ProtectedRoute({ element }) {
  const [searchParams] = useSearchParams()
  const isAuthenticated = localStorage.getItem("cms_session_id") || searchParams.get("cms_session_id");
  const location = useLocation();

  return isAuthenticated  ? (
    element
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
