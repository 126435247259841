import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import { star } from "../../utils/SvgIcons";
import ListIsEmpty from "../../images/List_is_empty.svg";
import "./MaterialUiTable.css";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700, // Set a minimum width for the table if needed
  },
  tableCell: {
    whiteSpace: "nowrap",
    padding: theme.spacing(1),
    borderBottom: "none",
    height: 50, // Adjust the row height as needed
    backgroundColor: "white",
    boxShadow: "none",
    fontSize: "12px",
  },
  evenRow: {
    backgroundColor: "white", // Light Sky Blue
  },
  root: {
    flexShrink: 0,
    marginLeft: "auto",
    height: "52px",
    width: "100%",
  },
  selectRoot: {
    marginRight: "16px", // Adjust this as needed
  },
}));

const MaterialUITable = ({
  columns,
  data,
  rowClickHandler,
  rowsShowPerPage,
  table,
  adsAdmin,
  selectedRow,
  allChats,
  hidePagination
}) => {
  const classes = useStyles();
  const [sortKey, setSortKey] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsShowPerPage ? rowsShowPerPage : 5
  );

  const handleSort = (key) => {
    if (key === sortKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortDirection("asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = sortKey
    ? data &&
      [...data].sort((a, b) => {
        const valueA = a[sortKey];
        const valueB = b[sortKey];
        if (valueA < valueB) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      })
    : data;

  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (row) => {
    if (rowClickHandler) {
      rowClickHandler(row);
    }
  };

  const getColumnValue = (row, column) => {
    const { key, formatter } = column;

    const value = row[key];

    if (formatter && typeof formatter === "function") {
      return formatter(value, row);
    }

    if (key === "status") {
      if (value === "REJECTED") {
        return (
          <Tooltip title="Delete">
            <div className="reject-info-table-finance">
              <p className="reject-info-text-request">
                Rejected <img src={star} />{" "}
              </p>
            </div>
          </Tooltip>
        );
      } else if (value === "ACTIVE") {
        return (
          <p
            style={{
              color: "#5FB4F2",
            }}
          >
            Active
          </p>
        );
        // return value?.toLowerCase()?.charAt(0).toUpperCase() + value?.toLowerCase().slice(1);
      } else if (value === "COMPLETED") {
        return (
          <p
            style={{
              color: "#017630",
            }}
          >
            Completed
          </p>
        );
      } else if (value === "INACTIVE") {
        return (
          <p
            style={{
              color: "#FFC540",
            }}
          >
            Inactive
          </p>
        );
      }
    }

    return value;
  };

  const userType = localStorage.getItem("type");

  return (
    <>
      <div
        className={`${classes?.tableContainer} ${
          data?.length === 0 && "hide-scroll"
        } generictable ${table ? "finance-table-center-container" : ""}`}
      >
        <Table className={`${classes?.table}  `}>
          <TableHead style={{ backgroundColor: "#e7efff" }}>
            <TableRow>
              {columns
                ?.filter((e) => {
                  if (e?.forAdmin && userType === "ADMIN") {
                    return e;
                  }
                  if (e.forAdmin === undefined) {
                    return e;
                  }
                })
                .map((column, ind) => {
                  return (
                    <TableCell
                      key={ind}
                      className={`${classes.tableCell} tableCell `}
                      style={{
                        backgroundColor: "#F4F5FF",
                      }}
                    >
                      <div
                        className={`table-cell ${
                          column?.center ? " flex-center-center" : ""
                        } ${table ? "finance-table-center" : ""}`}
                        style={{
                          justifyContent: adsAdmin && "flex-start",
                          alignItems: adsAdmin && "flex-start",
                          paddingLeft: "5px",
                        }}
                      >
                        <TableSortLabel
                          active={sortKey === column.key}
                          direction={
                            sortKey === column.key ? sortDirection : "asc"
                          }
                          onClick={() => handleSort(column.key)}
                        >
                          <p className="heading-name-generic">{column.label}</p>
                        </TableSortLabel>
                      </div>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData &&
              paginatedData.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    className={
                      index % 2 === 0
                        ? `${classes.evenRow} table-row-body cursor-p`
                        : "table-row-body cursor-p"
                    }
                    onClick={() => handleRowClick(row)}
                    style={{
                      borderBottom: "1px solid #F3F3F3",
                      backgroundColor: allChats
                        ? selectedRow?.chat_sub_session_id ===
                            row?.chat_sub_session_id && "#8369fc"
                        : selectedRow?.id === row?.id && "#8369fc",
                    }}
                  >
                    {columns
                      .filter((e) => {
                        if (e?.forAdmin && userType === "ADMIN") {
                          return e;
                        }
                        if (e.forAdmin === undefined) {
                          return e;
                        }
                      })
                      .map((column, ind) => {
                        if (column?.onclickFunction) {
                          return (
                            <TableCell
                              onClick={() => {
                                column?.onclickFunction(row);
                              }}
                              key={ind}
                              className={`${classes.tableCell} tableCell ${
                                column?.center ? "flex-center-center" : ""
                              }`}
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              <div className="table-cell ">
                                {getColumnValue(row, column)}
                              </div>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={ind}
                              className={`${classes.tableCell} tableCell ${
                                column?.center ? " flex-center-center" : ""
                              }`}
                              style={
                                allChats
                                  ? {
                                      cursor:
                                        row?.status === "OFFLINE" &&
                                        "not-allowed",
                                      backgroundColor:
                                        selectedRow?.chat_sub_session_id ===
                                        row?.chat_sub_session_id
                                          ? "#8369fc"
                                          : row?.status === "ONLINE"
                                          ? selectedRow?.id === row?.id &&
                                            "#8369fc"
                                          : row?.status === "OFFLINE" &&
                                            "#dadada",
                                    }
                                  : {
                                      background: "#fff",
                                    }
                              }
                            >
                              <div
                                className={`table-cell ${
                                  table ? "finance-table" : ""
                                }`}
                                // style={{}}
                              >
                                {getColumnValue(row, column)}
                              </div>
                            </TableCell>
                          );
                        }
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      {data?.length === 0 && (
        <div
          className={`empty-list-container ${table} ? 'error-finance-message' : ''`}
        >
          <img
            src={ListIsEmpty}
            alt="empty list img"
            className="empty-list-img"
          />
          <p className="empty-list-message">
            Sorry,we couldn't find any data, Try searching something else
          </p>
        </div>
      )}
      {( !hidePagination) && (
        <div
          style={{ height: "52px", width: "100%" }}
          className="flex-flex-end-center  position-relative"
        >
          <TablePagination
            component="div"
            count={sortedData?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}

            // classes={{
            //   root: classes.root,
            //   selectRoot: classes.selectRoot,
            // }}
          />
        </div>
      )}
    </>
  );
};

export default MaterialUITable;
