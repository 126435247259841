import React from "react";
import { right_svg } from "../../../utils/SvgIcons";
import { RenderHtml } from "../../../utils/LocalFunctions";
import { formatTimeStamp } from "../../../utils";
import { renderChatOptionsToggle } from "../../../utils/utils";

export default function OPTION_LIST({
  message,
  click_enable,
  optionClickHandler,
  selected_options,
  latest,
  bot_type,
  toggleOptions,
}) {
  return (
    <div>
      <p className="message-text">{RenderHtml(message?.message)}</p>
      {renderChatOptionsToggle(bot_type,toggleOptions,message?.sender_type) &&
        message?.options?.map((e) => {
          return (
            <div
              onClick={() => {
                if (click_enable) {
                  optionClickHandler({
                    selection_type: message.selection_type,
                    message_type: message.type,
                    ...e,
                  });
                }
              }}
              className="option-container mt-12px"
            >
              <p className="option-text">{e?.text}</p>
              {selected_options.find((o) => o?.text === e?.text) && latest && (
                <div className="right_svg_icon">{right_svg}</div>
              )}
            </div>
          );
        })}
      <p className="chat-time-stamp">{formatTimeStamp(message?.timestamp)}</p>
    </div>
  );
}
