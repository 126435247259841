import React from "react";
import moment from "moment";
export function formatBold(text) {
  // First, split the text by line breaks to handle bullet points separately
  const lines = text.split("\n");

  // Process each line individually
  return lines.map((line, lineIndex) => {
    // This regex matches double asterisks for bold text
    const regex = /\*\*(.*?)\*\*/g;

    // Replace the bold text and keep the rest unchanged
    const processedLine = line.split(regex).map((part, index) => {
      if (index % 2 === 1) {
        // Wrap the bold text in <strong> tags
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });

    // Join the processed parts back together
    const finalLine = <span key={lineIndex}>{processedLine}</span>;

    // Add a line break after each line except the last one
    return (
      <React.Fragment key={lineIndex}>
        {finalLine}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    );
  });
}

export function monthcode(month) {
  switch (month) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "Aug";
    case "09":
      return "Sept";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
    default:
      return "";
  }
}

export const capsLetter = (str) => {
  let str2 = str?.toLowerCase().replace("_", " ");
  let str1 = "";
  if (str2) {
    str1 = str2[0]?.toUpperCase() + str2?.slice(1);
  }
  return str1 ? str1 : "";
};

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function formatDateToMonthDayYear(dateString) {
  let [year, month, day] = dateString.split("-");
  return `${monthcode(month)} ${day}, ${year}`;
}

const todaysDate = moment().format("YYYY-MM-DD");

export const dateRanges = [
  {
    label: "Today",
    startDate: todaysDate,
    endDate: todaysDate,
  },
  {
    label: "Last 7 Days",
    startDate: moment(todaysDate).subtract(6, "days").format("YYYY-MM-DD"),
    endDate: todaysDate,
  },
  {
    label: "Last 15 Days",
    startDate: moment(todaysDate).subtract(14, "days").format("YYYY-MM-DD"),
    endDate: todaysDate,
  },
  {
    label: "Last 30 Days",
    startDate: moment(todaysDate).subtract(29, "days").format("YYYY-MM-DD"),
    endDate: todaysDate,
  },
  {
    label: "Last 3 Months",
    startDate: moment(todaysDate).subtract(3, "months").format("YYYY-MM-DD"),
    endDate: todaysDate,
  },
  {
    label: "Last 6 Months",
    startDate: moment(todaysDate).subtract(6, "months").format("YYYY-MM-DD"),
    endDate: todaysDate,
  },
  {
    label: "Last Year",
    startDate: moment(todaysDate).subtract(1, "years").format("YYYY-MM-DD"),
    endDate: todaysDate,
  },
];

export function convertToTime(totalSeconds) {
  totalSeconds = Math.max(0, parseInt(totalSeconds, 10));

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (totalSeconds < 60) {
    return `${seconds} secs`;
  } else if (totalSeconds < 3600) {
    return `${minutes}:${seconds.toString().padStart(2, "0")} mins`;
  } else {
    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
}

export function formatDate(value) {
  const date = new Date(value);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function renderChatOptionsToggle(botType, toggleOptions, user_type) {
  if (!botType) {
    return true;
  } else if (user_type === "BOT") {
    if (botType && toggleOptions) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

// Example usage:

export const filterbystatus = (statusFilter) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ marginRight: "8px" }}
  >
    <mask
      id="mask0_1712_10966"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1712_10966)">
      <path
        d="M6.66667 12V10.6667H9.33333V12H6.66667ZM4 8.66667V7.33333H12V8.66667H4ZM2 5.33333V4H14V5.33333H2Z"
        fill="#1C1B1F"
      />
      {statusFilter.length !== 0 && (
        <circle cx="13.3332" cy="4.0026" r="2.66667" fill="#8369fc" />
      )}
    </g>
  </svg>
);

export const ticketCategoryColorCodes = {
  DELAYED_IN_DELIVERY: "linear-gradient(135deg, #FF9A8B, #FF6A88)",
  OFFER_RELATED_ISSUE: "linear-gradient(135deg, #FDBB2D, #22C1C3)",
  REFERRAL: "linear-gradient(135deg, #DCE35B, #45B649)",
  REFUND_RELATED: "linear-gradient(135deg, #F77062, #FE5196)",
  WALLET_BALANCE_USAGE: "linear-gradient(135deg, #00C9FF, #92FE9D)",
  MISSING_PRODUCT_DELIVERED: "linear-gradient(90deg, #FFD194 0%, #D1913C 100%)",
  DAMAGED_PRODUCT_DELIVERED: "linear-gradient(135deg, #FFB75E, #ED8F03)",
  WRONG_PRODUCT_DELIVERED: "linear-gradient(135deg, #C33764, #1D2671)",
  EXPIRED_PRODUCT_DELIVERED: "linear-gradient(135deg, #FFE000, #799F0C)",
  ORDER_NOT_DELIVERED_BUT_SHOWING_DELIVERED:
    "linear-gradient(135deg, #56CCF2, #2F80ED)",
  ASKING_FOR_DELIVERY_EXECUTIVE_NUMBER:
    "linear-gradient(135deg, #00C6FF, #0072FF)",
  ADDRESS_OR_PINCODE_OR_PHONENUMBER_CHANGE:
    "linear-gradient(135deg, #D9AFD9, #97D9E1)",
  ORDER_MODIFICATION: "linear-gradient(135deg, #FAD961, #F76B1C)",
  OTP_ISSUE: "linear-gradient(135deg, #8EC5FC, #E0C3FC)",
  APP_RELATED_TECHNICAL_ISSUE:
    "linear-gradient(135deg, #3A1C71, #D76D77, #FFAF7B)",
  NOT_HAPPY_WITH_RESOLUTION_PROVIDED:
    "linear-gradient(135deg, #FF9966, #FF5E62)",
  CANCEL_ORDER: "linear-gradient(135deg, #F0F2F0, #000C40)",
  OTHERS: "linear-gradient(135deg, #DA4453, #89216B)",
};

export const ticketCategoryList = [
  "DELAYED_IN_DELIVERY",
  "OFFER_RELATED_ISSUE",
  "REFERRAL",
  "REFUND_RELATED",
  "WALLET_BALANCE_USAGE",
  "MISSING_PRODUCT_DELIVERED",
  "DAMAGED_PRODUCT_DELIVERED",
  "WRONG_PRODUCT_DELIVERED",
  "EXPIRED_PRODUCT_DELIVERED",
  "ORDER_NOT_DELIVERED_BUT_SHOWING_DELIVERED",
  "ASKING_FOR_DELIVERY_EXECUTIVE_NUMBER",
  "ADDRESS_OR_PINCODE_OR_PHONENUMBER_CHANGE",
  "ORDER_MODIFICATION",
  "OTP_ISSUE",
  "APP_RELATED_TECHNICAL_ISSUE",
  "NOT_HAPPY_WITH_RESOLUTION_PROVIDED",
  "CANCEL_ORDER",
  "OTHERS",
];
