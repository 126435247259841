import React, { useState, useEffect } from "react";
import "./AllChats.css";
import axios from "axios";
import { get_api } from "../../utils/LocalFunctions";
import MaterialUITable from "../../components/material-ui-table/MaterialUiTable";
import ChatInterface from "../../components/chatInterface/ChatInterface";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ListIsEmpty from "../../images/List_is_empty.svg";
import { useSearchParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import "../../components/material-ui-table/MaterialUiTable.css";
import {
  capsLetter,
  dateRanges,
  filterbystatus,
  formatDate,
  formatDateToMonthDayYear,
  removeItemOnce,
} from "../../utils/utils";
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import { clear_all_svg, crossBlack, filterbydate } from "../../utils/SvgIcons";
import Pagination from "../../components/pagination/Pagination";
import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";

export function formatDateRange(dateRangeString, str) {
  if (dateRangeString === str) {
    return {
      start_date: "",
      end_date: "",
    };
  }
  const [startDateString, endDateString] = dateRangeString.split(" -> ");

  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  const formattedStartDate = `${startDate.getFullYear()}-${(
    startDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
  const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;

  const result = {
    start_date: formattedStartDate,
    end_date: formattedEndDate,
  };

  return result;
}

function AllChats(props) {
  const { dailychats } = props;
  const maxDate = moment().format("YYYY-MM-DD");
  const todaysDate = maxDate;
  const [searchParams] = useSearchParams();
  let [initialDateRange, setInitialDaterange] = useState({
    endDate: todaysDate,
    startDate: todaysDate,
  });

  const [selectedRange, setSelectedRange] = useState({
    endDate: dailychats ? todaysDate : null,
    startDate: dailychats ? todaysDate : null,
    // endDate: todaysDate,
    // startDate: "2024-09-01",
  });
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [dateFilterText, setDateFilterText] = useState(
    dailychats
      ? searchParams.get("date_range")
        ? searchParams.get("date_range")
        : selectedRange?.startDate !== null &&
          `${formatDateToMonthDayYear(
            selectedRange.startDate
          )} -> ${formatDateToMonthDayYear(selectedRange.endDate)}`
      : searchParams.get("date_range")
      ? searchParams.get("date_range")
      : "Filter Date Created"
  );
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [selectedChatUser, setSelectedChatUser] = useState({});
  const [chatList, setChatList] = useState([]);
  const [chat_history, setChatHistory] = useState([]);
  const navigate = useNavigate();

  const chat_id = searchParams.get("chat_id")
    ? searchParams.get("chat_id")
    : "";
  const chat_subsession_id = searchParams.get("chat_subsession_id")
    ? searchParams.get("chat_subsession_id")
    : "";
  const user_id = searchParams.get("user_id")
    ? searchParams.get("user_id")
    : "";
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false);
  const [isRatingDropDownOpen, setIsRatingDropDownOpen] = useState(false);
  const [isAgentDropDownOpen, setIsAgentDropDownOpen] = useState(false);
  const [iscsatDropDownOpen, setCsatDropDownOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState(
    searchParams.get("status") ? searchParams.get("status").split(",") : []
  );
  const [ratingFilter, setRatingFilter] = useState(
    searchParams.get("rating")
      ? searchParams
          .get("rating")
          .split(",")
          .map((e) => parseInt(e))
      : []
  );
  const [filterAgentsList, setFilterAgentsList] = useState(
    searchParams.get("agent")
      ? searchParams
          .get("agent")
          .split(",")
          .map((e) => parseInt(e))
      : []
  );

  const [csatFilter, setCsatFilter] = useState(
    searchParams.get("csat") ? searchParams.get("csat").split(",") : []
  );
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("current_page")
      ? parseInt(searchParams.get("current_page"))
      : 1
  );
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [agentsList, setAgentsList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [selectedDailyChatStatus, setSelectedDailyChatStatus] = useState("");
  const [filteredSummary, setFilteredSummary] = useState({});
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [reassignApi, setReassignApi] = useState(false);

  const agent_email = localStorage.getItem("cms_email");
  const api = get_api();
  let alltypes = [
    "CLOSED",
    "PENDING_AGENT_ASSIGNMENT",
    "AGENT_ASSIGNED",
    "BOT_ASSIGNED",
  ];

  const statusColorCodes = {
    BOT_ASSIGNED: "#FF0080",
    AGENT_ASSIGNED: "green",
    PENDING_AGENT_ASSIGNMENT: "#640D5F",
    CLOSED: "#FA7070",
  };

  useEffect(() => {
    const { start_date, end_date } = formatDateRange(
      searchParams.get("date_range") ? searchParams.get("date_range") : "",
      ""
    );

    setSelectedRange({ endDate: end_date, startDate: start_date });
    setInitialDaterange({ endDate: end_date, startDate: start_date });
  }, []);

  //find seelected user
  useEffect(() => {
    if (chat_id || chatList.length > 0 || chat_subsession_id) {
      const selectedChat = chatList?.find(
        (chat) =>
          chat.chat_id === parseInt(chat_id) &&
          chat?.chat_sub_session_id === parseInt(chat_subsession_id)
      );
      setSelectedChatUser(selectedChat || {});
    }
  }, [chatList?.length > 0, chat_subsession_id, chat_id]);

  useEffect(() => {
    setSelectedChatUser({});
    setChatHistory([]);

    get_chats();
  }, [
    statusFilter,
    initialDateRange,
    ratingFilter,
    filterAgentsList,
    csatFilter,
    selectedDailyChatStatus,
  ]);

  useEffect(() => {
    get_chats("true");
  }, [currentPage]);

  useEffect(() => {
    axios
      .get(`${api}/csa/`)
      .then((res) => {
        setAgentsList(res?.data?.result);
      })
      .catch((e) => {
        console.log(e?.message);
      });
  }, []);

  useEffect(() => {
    if (selectedChatUser?.chat_sub_session_id || selectedChatUser?.chat_id) {
      getchatHistory();
    }
  }, [selectedChatUser?.chat_sub_session_id, selectedChatUser?.chat_id]);

  useEffect(() => {
    const { start_date, end_date } = formatDateRange(dateFilterText, "");
    navigate({
      pathname: window.location.pathname,
      search: `?chat_id=${chat_id}&chat_subsession_id=${chat_subsession_id}&status=${statusFilter}&date_range=${
        dateFilterText === "Filter Date Created" ? "" : `${dateFilterText}`
      }&search=${searchTerm}&csat=${csatFilter}&rating=${ratingFilter}&agent=${filterAgentsList}&current_page=${currentPage}&user_id=${user_id}`,
    });
  }, [
    statusFilter,
    dateFilterText,
    searchTerm,
    csatFilter,
    ratingFilter,
    filterAgentsList,
    currentPage,
    selectedChatUser,
  ]);

  const getchatHistory = () => {
    axios
      .get(
        `${api}/chatsession/${selectedChatUser?.chat_id}/?source=BOT&chat_sub_session_id=${selectedChatUser?.chat_sub_session_id}`
      )
      .then((res) => {
        setChatHistory(res?.data?.content?.message);
        //   scrollChatToBottom();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_chats = (change_current_page) => {
    const offset = (currentPage - 1) * 10;
    if (dailychats) {
      axios
        .get(
          `${api}/chatsubsessions/?&status=${selectedDailyChatStatus}&agent_id=${filterAgentsList.join(
            ","
          )}&start_date=${selectedRange?.startDate} 00:00:00&end_date=${
            selectedRange?.endDate
          } 23:59:59&offset=${offset}&limit=10&search_term=${searchTerm.trim()}`
        )
        .then((res) => {
          setChatList(res?.data?.result);
          setTotalPages(res?.data?.total_sub_session);
          setFilteredChatList(res?.data?.result);
          setFilteredSummary(res?.data?.summary?.unfiltered_summary);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${api}/chatsubsessions/?rating=${ratingFilter}&status=${statusFilter}&agent_id=${filterAgentsList}&csat=${csatFilter}&start_date=${selectedRange?.startDate}&end_date=${selectedRange?.endDate}&offset=${offset}&limit=10&search_term=${searchTerm}&user_id=${user_id}`
        )
        .then((res) => {
          const totalpages = res?.data?.total_sub_session;
          setChatList(res?.data?.result);
          setTotalPages(totalpages);
          setFilteredChatList(res?.data?.result);
          setFilteredSummary(res?.data?.summary?.unfiltered_summary);
          if (
            change_current_page === undefined &&
            Math.ceil(totalPages / 10) < currentPage
          ) {
            setCurrentPage(1);
          }
        })
        .catch((err) => {
          console.log(err);
          setSearchTerm("");
        });
    }
  };

  const renderallStates = () => {
    return alltypes.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            if (statusFilter.includes(e)) {
              setStatusFilter([...removeItemOnce(statusFilter, e)]);
            } else {
              setStatusFilter([...statusFilter, e]);
            }
          }}
          style={{
            textWrap: "nowrap",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
          key={e}
        >
          {statusFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e).replaceAll("_", " ")}
        </p>
      );
    });
  };

  const renderallRating = () => {
    let alltypes = [1, 2, 3, 4, 5];
    return alltypes.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            if (ratingFilter.includes(e)) {
              setRatingFilter([...removeItemOnce(ratingFilter, e)]);
            } else {
              setRatingFilter([...ratingFilter, e]);
            }
          }}
          key={e}
          style={{
            textWrap: "nowrap",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {ratingFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {e}
        </p>
      );
    });
  };

  const renderallAgents = () => {
    return agentsList.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            if (filterAgentsList.includes(e?.id)) {
              setFilterAgentsList([...removeItemOnce(filterAgentsList, e?.id)]);
            } else {
              setFilterAgentsList([...filterAgentsList, e?.id]);
            }
          }}
          key={e}
          style={{
            textWrap: "nowrap",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {filterAgentsList.includes(e?.id) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {e?.name}
        </p>
      );
    });
  };

  const renderallCsat = () => {
    const allCsat = [ "no", "yes"];
    return allCsat.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            if (csatFilter.includes(e)) {
              setCsatFilter([...removeItemOnce(csatFilter, e)]);
            } else {
              setCsatFilter([e]);
            }
          }}
          key={e}
          style={{
            textWrap: "nowrap",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {csatFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e)}
        </p>
      );
    });
  };

  const changeSelectedRange = (e) => {
    let startDate = moment(new Date(e["startDate"])).format("YYYY-MM-DD");
    let endDate = moment(new Date(e["endDate"])).format("YYYY-MM-DD");
    setSelectedRange({
      startDate: startDate,
      endDate: endDate,
    });
  };

  const saveDateClickHandler = () => {
    setInitialDaterange({
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate,
    });
    setDateFilterText(
      `${formatDateToMonthDayYear(
        selectedRange.startDate
      )} -> ${formatDateToMonthDayYear(selectedRange.endDate)}`
    );
    setIsDateDropdownOpen(false);
  };

  function clearAllFilters() {
    if (dailychats) {
      setSelectedRange({
        endDate: todaysDate,
        startDate: todaysDate,
      });
      setDateFilterText(
        `${formatDateToMonthDayYear(todaysDate)} -> ${formatDateToMonthDayYear(
          todaysDate
        )}`
      );
    } else {
      setSelectedRange({
        endDate: null,
        startDate: null,
      });
      setDateFilterText("Filter Date Created");
      setSelectedDailyChatStatus("");
    }
    setStatusFilter([]);
    setRatingFilter([]);
    setFilterAgentsList([]);
    setCsatFilter([]);
    setSearchTerm("");
  }

  async function bulkReassign() {
    setReassignApi(true);
    const filteredValues = [...filteredChatList];

    try {
      const response = await axios.post(
        `https://ancillarygcp-prod.smytten.com/chatchipmunk/bulkreassign/`,
        {
          chat_session_ids: filteredValues?.map((e) => e?.chat_id),
        }
      );
      if (response) {
        setReassignApi(false);
        setOpenReassignModal(false);
      }
    } catch (e) {
      console.log(e?.message);
    }
  }

  function renderFilterByDateContent(type) {
    return (
      <div
        className="height-100"
        style={{
          marginRight: !type && "10px",
        }}
      >
        <Dropdown
          align={"end"}
          id={
            type
              ? "survey-date-filter-dropdown-daily-chats"
              : "survey-date-filter-dropdown"
          }
          className="survey-date-filter"
          show={isDateDropdownOpen}
          onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
        >
          <Dropdown.Toggle className="width-100 height-100" style={{}}>
            <div className="flex-center-center toggle-text-filter">
              {filterbydate(dateFilterText)}
              {dateFilterText}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            <div className="date-filter-calender-container">
              {isDateDropdownOpen && (
                <DateRangePicker
                  open={isDateDropdownOpen}
                  onChange={(e) => changeSelectedRange(e)}
                  definedRanges={dateRanges}
                  initialDateRange={initialDateRange}
                  maxDate={todaysDate}
                />
              )}

              <div
                style={{ padding: "0px 10px" }}
                className="flex-space-between-center"
              >
                <p
                  onClick={() => {
                    setDateFilterText("Filter Date Created");
                    setIsDateDropdownOpen(false);
                    setInitialDaterange({
                      startDate: todaysDate,
                      endDate: todaysDate,
                    });
                    setSelectedRange({
                      startDate: todaysDate,
                      endDate: todaysDate,
                    });
                  }}
                  className="flex-flex-start-center clear-all-2C66D7 cursor-p"
                >
                  <span>{clear_all_svg()}</span>
                  Clear All
                </p>
                <p
                  onClick={() => {
                    saveDateClickHandler();
                  }}
                  className="clear-all-2C66D7 cursor-p"
                >
                  Save
                </p>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function renderFilterByAgentContent(type) {
    return (
      <div
        style={{
          height: "100%",
          marginRight: type && "10px",
        }}
      >
        <Dropdown
          className="survey-date-filter"
          id="survey-status-filter-dropdown"
          show={isAgentDropDownOpen}
          onToggle={(isOpen) => setIsAgentDropDownOpen(isOpen)}
        >
          <Dropdown.Toggle>
            <div className="flex-center-center toggle-text-filter">
              {filterbystatus(filterAgentsList)} Filter by Agent
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            <p
              className="single-status-filter all-chats-filter-alignment"
              onClick={() => setFilterAgentsList([])}
            >
              {filterAgentsList?.length === 0 ? (
                <FontAwesomeIcon
                  className="mr-8px"
                  icon={faCheck}
                ></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon
                  className="mr-8px"
                  style={{ visibility: "hidden" }}
                  icon={faCheck}
                ></FontAwesomeIcon>
              )}
              {"Clear"}
            </p>
            {renderallAgents()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function handleSearchInputChange(e) {
    setSearchTerm(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      get_chats();
    }
  }

  return (
    <div className="all-chats-main-container width-100">
      <div className="all-chats-left-container pl-10px">
        {/* <div className="mt-10px pt-10px pl-10px">
          <input
            type="text"
            placeholder="Search here..."
            value={searchTerm}
            onChange={(e) => handleSearchInputChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            className="templates-search-bar"
            style={{
              marginBottom: "10px",
              border: "1px solid #dadada",
              fontSize: "14px",
              padding: "8px",
            }}
          />
        </div> */}
        <div
          className=" width-100"
          style={{
            marginBottom: "12px",
            alignItems: "center",
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {!dailychats && (
            <input
              type="text"
              placeholder="Search here..."
              value={searchTerm}
              onChange={(e) => handleSearchInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              className="templates-search-bar"
              style={{
                marginBottom: "0px",
                border: "1px solid #dadada",
                fontSize: "14px",
                padding: "5px",
                width: "220px",
              }}
            />
          )}

          {dailychats ? (
            <div className="flex-column wrapper-daily-chats-next-line-content width-100">
              <div className="wrapper-daily-chats-container flex-flex-start-center width-100">
                <div className="all-status-daily-chats width-100">
                  {Object.keys(filteredSummary)?.map((status) => (
                    <div
                      className="single-status-with-count width-100"
                      style={{
                        color: statusColorCodes[status],
                        borderBottom:
                          selectedDailyChatStatus === status &&
                          "2px solid #0E46A3",
                        fontWeight:
                          selectedDailyChatStatus === status && "bold",
                        backgroundColor:
                          selectedDailyChatStatus === status && "#FFF7FC",
                      }}
                      key={status}
                      onClick={() => setSelectedDailyChatStatus(status)}
                    >
                      <p className="status-single-btn-daily cursor-p">
                        {capsLetter(status).replaceAll("_", " ")}{" "}
                      </p>

                      <p className="count-single-status">
                        {`(${filteredSummary?.[status]})`}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{ display: "flex", gap: "16px" }}
                className="flex-flex-start-center"
              >
                <input
                  type="text"
                  placeholder="Search here..."
                  value={searchTerm}
                  onChange={(e) => handleSearchInputChange(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  className="templates-search-bar"
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #dadada",
                    fontSize: "14px",
                    padding: "5px",
                    width: "220px",
                  }}
                />
                {renderFilterByDateContent("daily")}
                {renderFilterByAgentContent()}
                <button
                  className="clear-all-filters-daily-chats cursor-p"
                  onClick={() => setOpenReassignModal(true)}
                  disabled={selectedDailyChatStatus !== "AGENT_ASSIGNED"}
                >
                  Bulk Reassign
                </button>
                <p
                  className="clear-all-filters-daily-chats cursor-p"
                  onClick={() => clearAllFilters()}
                  style={{
                    padding: "5px",
                  }}
                >
                  {crossBlack}
                </p>
              </div>
            </div>
          ) : (
            <>
              <div
                className="height-100"
                style={{
                  marginRight: "10px",
                }}
              >
                <Dropdown
                  className="survey-date-filter"
                  id="survey-status-filter-dropdown"
                  show={iscsatDropDownOpen}
                  onToggle={(isOpen) => setCsatDropDownOpen(isOpen)}
                >
                  <Dropdown.Toggle>
                    <div className="flex-center-center toggle-text-filter">
                      {filterbystatus(csatFilter)} Filter by CSAT
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sortStateSectionHideMenu">
                    <p
                      className="single-status-filter all-chats-filter-alignment"
                      onClick={() => setCsatFilter([])}
                    >
                      {csatFilter?.length === 0 ? (
                        <FontAwesomeIcon
                          className="mr-8px"
                          icon={faCheck}
                        ></FontAwesomeIcon>
                      ) : (
                        <FontAwesomeIcon
                          className="mr-8px"
                          style={{ visibility: "hidden" }}
                          icon={faCheck}
                        ></FontAwesomeIcon>
                      )}
                      {"Clear"}
                    </p>
                    {renderallCsat()}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="height-100"
                style={{
                  marginRight: "10px",
                }}
              >
                <Dropdown
                  className="survey-date-filter"
                  id="survey-status-filter-dropdown"
                  show={isRatingDropDownOpen}
                  onToggle={(isOpen) => setIsRatingDropDownOpen(isOpen)}
                >
                  <Dropdown.Toggle>
                    <div className="flex-center-center toggle-text-filter">
                      {filterbystatus(ratingFilter)} Filter by Rating
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sortStateSectionHideMenu">
                    <p
                      className="single-status-filter all-chats-filter-alignment"
                      onClick={() => setRatingFilter([])}
                    >
                      {ratingFilter?.length === 0 ? (
                        <FontAwesomeIcon
                          className="mr-8px"
                          icon={faCheck}
                        ></FontAwesomeIcon>
                      ) : (
                        <FontAwesomeIcon
                          className="mr-8px"
                          style={{ visibility: "hidden" }}
                          icon={faCheck}
                        ></FontAwesomeIcon>
                      )}
                      {"Clear"}
                    </p>
                    {renderallRating()}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {renderFilterByAgentContent("agent")}
              <div
                className="height-100"
                style={{
                  marginRight: "10px",
                }}
              >
                <Dropdown
                  className="survey-date-filter"
                  id="survey-status-filter-dropdown"
                  show={isStatusDropDownOpen}
                  onToggle={(isOpen) => setIsStatusDropDownOpen(isOpen)}
                >
                  <Dropdown.Toggle>
                    <div className="flex-center-center toggle-text-filter">
                      {filterbystatus(statusFilter)} Filter by Status
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sortStateSectionHideMenu">
                    <p
                      className="single-status-filter all-chats-filter-alignment"
                      onClick={() => setStatusFilter([])}
                    >
                      {statusFilter?.length === 0 ? (
                        <FontAwesomeIcon
                          className="mr-8px"
                          icon={faCheck}
                        ></FontAwesomeIcon>
                      ) : (
                        <FontAwesomeIcon
                          className="mr-8px"
                          style={{ visibility: "hidden" }}
                          icon={faCheck}
                        ></FontAwesomeIcon>
                      )}
                      {"Clear"}
                    </p>
                    {renderallStates()}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {renderFilterByDateContent()}

              <p
                className="clear-all-filters-daily-chats cursor-p"
                onClick={() => clearAllFilters()}
                style={{
                  padding: "5px",
                }}
              >
                {crossBlack}
              </p>
            </>
            // </div>
          )}
        </div>
        <div
          className="flex-column"
          style={{
            gap: "24px",
          }}
        >
          <MaterialUITable
            columns={[
              {
                key: "chat_sub_session_id",
                label: "Subsession Id",
                formatter: (value) => {
                  return <p className="pl-10px">{value}</p>;
                },
              },
              {
                key: "user_name",
                label: "User Name",
              },
              {
                key: "title",
                label: "Title",
              },
              {
                key: "agent_name",
                label: "Agent Name",
              },
              {
                key: "status",
                label: "Status",
                formatter: (value) => {
                  return <p className="pl-10px">{capsLetter(value)}</p>;
                },
              },
              {
                key: "rating",
                label: "Rating",
              },

              {
                key: "created_at",
                label: "Created At",
                formatter: (value) => {
                  return <p className="pl-10px">{formatDate(value)}</p>;
                },
              },
              {
                key: "csat",
                label: "CSAT",
              },
            ]}
            data={filteredChatList}
            rowClickHandler={(row) => {
              setSelectedChatUser(row);
              if (dailychats) {
                navigate(
                  {
                    pathname: window.location.pathname,
                    search: `?chat_id=${row?.chat_id}&chat_subsession_id=${
                      row?.chat_sub_session_id
                    }&current_page=${currentPage}&status_filter=${statusFilter}&date_range=${
                      dateFilterText === "Filter Date Created"
                        ? ""
                        : dateFilterText
                    }&search=${searchTerm}`,
                  }
                  // `/dailychats?chat_id=${row?.chat_id}&chat_subsession_id=${row?.chat_sub_session_id}&current_page=${currentPage}`
                );
              } else {
                navigate(
                  {
                    pathname: window.location.pathname,
                    search: `?chat_id=${row?.chat_id}&chat_subsession_id=${
                      row?.chat_sub_session_id
                    }&current_page=${currentPage}&status_filter=${statusFilter}&date_range=${
                      dateFilterText === "Filter Date Created"
                        ? ""
                        : dateFilterText
                    }&search=${searchTerm}&user_id=${user_id}`,
                  }
                  // `/allchats?chat_id=${row?.chat_id}&chat_subsession_id=${row?.chat_sub_session_id}&current_page=${currentPage}`
                );
              }
            }}
            rowsShowPerPage={10}
            allChats={"true"}
            selectedRow={selectedChatUser}
            hidePagination={true}
          />
          <div className="width-100 flex-center-center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalPages / 10)}
              onPageChange={(e) => {
                setCurrentPage(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="all-chats-right-container">
        <div className="chat-interface-wrapper-all-chats">
          {chat_history?.length > 0 ? (
            <ChatInterface
              allParams={{ sender_type: "USER" }}
              r_chat_history={[...chat_history]}
              allChats={"true"}
            />
          ) : (
            <div className={`empty-list-container`}>
              <p className="pt-10px"> Chat history</p>
              <img
                src={ListIsEmpty}
                alt="empty list img"
                className="empty-list-img"
              />
              <p className="empty-list-message">
                Sorry,we couldn't find any chats.
              </p>
            </div>
          )}
        </div>
      </div>
      {openReassignModal && (
        <ConfirmationModal
          show={openReassignModal}
          onHide={() => setOpenReassignModal(false)}
          saveClickHandler={() => bulkReassign()}
          api={reassignApi}
        />
      )}
    </div>
  );
}

export default AllChats;
