import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

export function get_seach_params() {
  const search = new URLSearchParams(window.location.search);
  let searchParams = {};
  search.forEach((value, key) => {
    searchParams[key] = value;
  });
  return searchParams;
}
export const get_api = () => {
  return process.env.REACT_APP_LOCAL_CHAT_API;
};

export const get_ancillary_prod_api = () => {
  return process.env.REACT_APP_ANCILLARY_PROD_API;
};
export function update_url(key, value) {
  const url = new URL(window.location);

  // Access the search parameters from the current URL
  const searchParams = url.searchParams;

  // Update or add the new search parameter
  searchParams.set(key, value);

  // Update the URL without affecting previous search params
  url.search = searchParams.toString();

  // Change the browser's URL without reloading the page
  window.history.pushState({}, "", url);
}

export function redirectToPath(pathname, target = "_blank") {
  // Create a new anchor element
  const anchor = document.createElement("a");

  // Set the href attribute to the provided pathname
  anchor.href = pathname;

  // Set the target attribute
  anchor.target = target;

  // Append the anchor to the document body
  document.body.appendChild(anchor);

  // Simulate a click event on the anchor to redirect the page
  anchor.click();

  // Clean up: remove the anchor from the DOM
  anchor.remove();
}

export const postData = async (apiUrl, dataObject) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataObject),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getData = async (apiUrl) => {
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const putData = async (apiUrl, dataObject) => {
  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataObject),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const createurl = (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch("https://ad-prod.smytten.com/advertisement/brand/7/uploadfile/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
        const imageUrl = data.url;
        resolve(imageUrl);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function convertToHtml(inputText) {
  // Replace newlines with <br> tags
  const textWithLineBreaks = inputText.replace(/\n/g, "<br>");

  // Allow only specific HTML tags, including <div>, <s>, <sup>, <sub>
  const htmlFormattedText = textWithLineBreaks
    // .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(
      /&lt;(\/?)(b|i|u|strong|em|a|br|p|ol|ul|li|div|s|sup|sub|span)(.*?)&gt;/g,
      "<$1$2$3>"
    );

  // Convert <a href="...">text</a> to clickable links with the necessary attributes
  const clickableLinksText = htmlFormattedText.replace(
    /<a href="(.*?)">(.*?)<\/a>/g,
    `<a href="$1" target="_blank" rel="noopener noreferrer">$2</a>`
  );
  return clickableLinksText.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}

export function RenderHtml(myHtml) {
  return <div dangerouslySetInnerHTML={{ __html: convertToHtml(myHtml) }} />;
}

export function RenderHtml1(myHtml) {
  return (
    <div
      className="rendered-html"
      dangerouslySetInnerHTML={{ __html: myHtml }}
    />
  );
}

export const parsevalue = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const cleanHtml = (htmlString) => {
  // Create a temporary div element to parse the HTML string
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Get all the <p> elements
  const paragraphs = tempDiv.getElementsByTagName("p");

  const spanArray = [];
  let hasContent = false; // Flag to check if we have any valid content

  for (let i = 0; i < paragraphs.length; i++) {
    const content = paragraphs[i].innerHTML.trim(); // Get trimmed content

    // Check if the content is neither empty nor just a <br> tag
    if (content && content !== "<br>") {
      hasContent = true; // We have some valid content
      const span = document.createElement("span");
      span.innerHTML = content; // Copy content from <p> to <span>
      spanArray.push(span); // Add the <span> to the array
      spanArray.push(document.createElement("br")); // Add <br /> after each <span>
    }
  }

  // Clear the temporary div and append the spans
  tempDiv.innerHTML = ""; // Clear previous content
  spanArray.forEach((element) => {
    tempDiv.appendChild(element);
  });

  // Remove the last <br> if it exists
  const lastChild = tempDiv.lastChild;
  if (lastChild && lastChild.nodeName === "BR") {
    tempDiv.removeChild(lastChild);
  }

  // Return an empty string if there is no content
  return hasContent ? tempDiv.innerHTML : "";
};

export function convertHtmlToText(html) {
  if (html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const paragraphs = tempDiv.getElementsByTagName("p");

    if (paragraphs.length > 0) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      const isEmpty =
        lastParagraph.innerHTML.trim() === "" ||
        lastParagraph.innerHTML === "<br>";
      if (isEmpty) {
        lastParagraph.remove();
      }
    }

    return tempDiv.innerText.replace(/\s+/g, " ").trim();
  }
  return "";
}
export function convertTextToHtml(text) {
  if (text) {
    // Split the text into lines based on new line characters (\n)
    const lines = text.split("\n").filter((line) => line.trim() !== "");

    // Wrap each line in a <p> tag
    const html = lines.map((line) => `<p>${line.trim()}</p>`).join("");

    return html;
  }
  return "";
}

export function GetSearchParams(removevalues = []) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const allParams = Object.fromEntries(params.entries());
  for (let i of removevalues) {
    delete allParams[i];
  }
  const queryString = new URLSearchParams(allParams).toString();

  return queryString;
}

export function GetacId() {
  const [searchParams] = useSearchParams();
  return searchParams.get("accountId") ? searchParams.get("accountId") : 1561;
}

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export function removeAllItems(arr, value) {
  return arr.filter((item) => item !== value);
}
export function removeAllFromArray(arr1, arr2) {
  return arr1.filter((item) => !arr2.includes(item));
}
export const containsAll = (arr1, arr2) => {
  if (arr1.length === 0) {
    return false;
  }
  return arr1.every((element) => arr2.includes(element));
};
