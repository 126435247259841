import React, { useState } from "react";
import addIcon from "../../images/bot_images/plus_icon_without_border.svg";
import { get_api, RenderHtml, RenderHtml1 } from "../../utils/LocalFunctions";
import { formatTimeStamp } from "../../utils";
import axios from "axios";
import {
  ADD_NOTE,
  EDIT_ESCALATED_TICKETS,
  EDIT_PENDING_TICKETS,
  EDIT_RAISED_TICKET,
  permissions,
} from "../../utils/permissions";

function AllNotes(props) {
  const {
    issue,
    notes,
    setNotes,
    ticketChatNote,
    getTicketChat,
    setRefreshTicketChat,
  } = props;
  const [addNotes, setAddNotes] = useState(false);
  const cms_id = localStorage.getItem("cms_id");
  const api = get_api();

  async function postTicketNotes(ticketId) {
    try {
      const response = await axios.post(`${api}/ticket_notes/`, {
        note: notes,
        cs_agent_id: parseInt(cms_id),
        ticket_id: ticketId,
      });
      setRefreshTicketChat(true);
      setNotes("");

      // getTicketNote();
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <div className="conversation-notes-container-tickets flex-column mb-12px">
      {addNotes
        ? permissions.includes(ADD_NOTE) && (
            <div className="add-notes-container-tickets-add flex-column">
              <p className="flex-start">Add Notes</p>
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter your notes here..."
                className="conversation-notes-input"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (notes !== "") {
                      postTicketNotes(issue?.id);
                    }
                  }
                }}
              />
            </div>
          )
        : (ticketChatNote?.status === "PENDING" ||
            ticketChatNote?.status === "RAISED_TICKET" ||
            ticketChatNote?.status === "USER_ACTION_REQUIRED" ||
            ticketChatNote?.status === "ESCALATED" ||
            ticketChatNote?.status === "USER_RESPONDED") &&
          permissions.includes(ADD_NOTE) &&
          (ticketChatNote?.status === "USER_ACTION_REQUIRED"
            ? permissions.includes(EDIT_RAISED_TICKET)
            : true) &&   (ticketChatNote?.status === "ESCALATED"
              ? permissions.includes(EDIT_ESCALATED_TICKETS)
              : true) &&  (ticketChatNote?.status === "PENDING"
                ? permissions.includes(EDIT_PENDING_TICKETS)
                : true) && (
            <div className="add-icon-notes-container flex-flex-start-center">
              <p> Add Note</p>
              <img
                src={addIcon}
                alt="add-icon"
                className="cursor-p"
                onClick={() => {
                  setAddNotes(true);
                }}
              />
            </div>
          )}
      {ticketChatNote?.ticket_notes?.length > 0 && (
        <div className="all-filtered-tickets-notes-wrapper flex-column">
          <p className="flex-start all-notes-of-ticket-container">All Notes</p>
          <div className="display-all-ticket-details-container flex-column flex-start">
            {ticketChatNote?.ticket_notes?.map(
              (note) =>
                note?.note !== "" && (
                  <div
                    key={note?.id}
                    className="single-note-for-ticket-content width-100 flex-column"
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="flex-start font-weight-600"
                      style={{
                        gap: "5px",
                      }}
                    >
                      <p className="font-weight-600">{note?.agent_name} :</p>{" "}
                      <p className="font-weight-600">{`${formatTimeStamp(
                        note?.created_at
                      )}`}</p>
                    </div>
                    {RenderHtml(note?.note)}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AllNotes;
