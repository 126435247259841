import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./ChatModal.css";
import { crossBlack } from "../../../utils/SvgIcons";

function ChatModal(props) {
  const { show, onHide, title, setChatText, apiCall, chatmodals } = props;
  const [message, setMessage] = useState(chatmodals.text);
  const inputRef = useRef(null);

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleSubmit = () => {
    setMessage("");
    if (message !== "") {
      apiCall(message);
    }
  };

  function renderTitle() {
    if (title === "FEEDBACK_REQUEST") {
      return "Request feedback";
    } else if (title === "END_CHAT") {
      return "End chat";
    } else if (title === "UPLOAD_IMAGE") {
      return "Image upload";
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="chat-modal-main-container flex-column">
        <div className="top-chats-text-container-modal flex-space-between">
          <p className="font-weight-600">{renderTitle()}</p>
          <span className="cursor-p" onClick={onHide}>
            {crossBlack}
          </span>
        </div>
        <Form>
          <Form.Group controlId="messageInput">
            <Form.Label className="font-14px">Enter your message:</Form.Label>
            <Form.Control
              ref={inputRef}
              type="text"
              placeholder="Type your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  !e.ctrlKey &&
                  !e.metaKey
                ) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              style={{
                borderRadius: "4px",
                padding: "10px",
                marginBottom: "20px",
                fontSize: "16px",
              }}
            />
          </Form.Group>
        </Form>

        <Button
          variant="primary"
          onClick={handleSubmit}
          style={{
            backgroundColor: "#8369fc",
            border: "transparent",
          }}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
}

export default ChatModal;
