import React from "react";
import { right_svg } from "../../../utils/SvgIcons";
import { RenderHtml } from "../../../utils/LocalFunctions";
import { formatTimeStamp } from "../../../utils";
import { renderChatOptionsToggle } from "../../../utils/utils";

export default function TRIAL_LIST({
  message,
  click_enable,
  optionClickHandler,
  selected_options,
  latest,
  bot_type,
  toggleOptions,
}) {
  return (
    <div>
      <p className="message-text">{RenderHtml(message.message)}</p>
      {renderChatOptionsToggle(bot_type,toggleOptions,message?.sender_type) &&
        message?.options?.map((e) => {
          return (
            <div
              onClick={() => {
                if (click_enable) {
                  optionClickHandler({
                    selection_type: message.selection_type,
                    message_type: message.type,
                    return_value: e.message,
                    text: e.message,
                    ...e,
                  });
                }
              }}
              style={{ gap: "8px" }}
              className="option-container flex-flex-start-flex-start single-option-container mt-12px"
            >
              <div className="trial-product-image-container">
                <img className="trial-product-image" src={e?.image} alt="" />
              </div>
              <div>
                <p className="trial-order-company-name text-left">
                  {e?.brand_name}
                </p>
                <p className="trial-order-name-text text-left">{e?.name}</p>
                <p className="mt-8px quantity-text text-left">
                  Quantity:{e?.quantity}
                </p>
              </div>
              {selected_options.find((o) => o?.name === e?.name) && latest && (
                <div className="right_svg_icon">{right_svg}</div>
              )}
            </div>
          );
        })}
      <p className="chat-time-stamp">{formatTimeStamp(message?.timestamp)}</p>
    </div>
  );
}
