// UserContext.js
import React, { createContext, useState } from "react";

// Create a context
export const RefreshContext = createContext();

// Create a provider component
export const RefreshProvider = ({ children }) => {
  const [refreshTickets, setRefreshTickets] = useState(false);

  return (
    <RefreshContext.Provider value={{refreshTickets,setRefreshTickets}}>
      {children}
    </RefreshContext.Provider>
  );
};
