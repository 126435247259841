import React from "react";
import "./ConfirmationModal.css";
import "../chat-modal/ChatModal.css";
import { Modal } from "react-bootstrap";
import { RenderHtml } from "../../../utils/LocalFunctions";

export default function ConfirmationModal1(props) {
  const { onHide, message, apiCall } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="chat-modal-main-container flex-column">
        <div
          style={{ maxHeight: "300px", overflowY: "scroll" }}
          className="text-left hide-scroll"
        >
          {RenderHtml(message)}
        </div>
        <div
          style={{ gap: "12px" }}
          className="width-100 flex-flex-end-center mt-20px"
        >
          <div
            onClick={() => {
              onHide();
            }}
            className="white-btn"
          >
            No
          </div>
          <div
            onClick={() => {
              apiCall();
            }}
            className="purple-btn"
          >
            Yes
          </div>
        </div>
      </div>
    </Modal>
  );
}
